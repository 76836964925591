import axios from "axios";

import {
    GET_ALL_LISTS,
    GET_LIST_DETAIL,
    GET_ALL_LISTS_SUCCESS,
    GET_LIST_SUCCESS,
    GET_ALL_LISTS_ERROR,
    GET_LIST_ERROR,
    UPDATE_LIST,
    UPDATE_LIST_SUCCESS,
    GET_USER_LIST,
    GET_USER_LIST_SUCCESS,
    ADD_FRIEND_LIST,
    GET_FRIEND_LIST,
    ACCEPT_FRIEND_LIST,
    REMOVE_FRIEND_LIST
} from "@/store/actions/list";

function initialState() {
    return {
        allList: [],
        list: {},
        userList: [],
    }
}

const state = initialState()

const getters = {
    allList: (state) => state.allList,
    List: (state) => state.list,
    userList: (state) => state.userList,
};

const actions = {
    [GET_ALL_LISTS]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios.get('/get-all-list')
                .then((response) => {
                    let allList = response.data.list
                    commit("GET_ALL_LISTS_SUCCESS", { allList });
                    resolve(response);
                })
                .catch((err) => {
                    commit("GET_ALL_LISTS_ERROR");
                    reject(err);
                });
        });
    },

    [GET_LIST_DETAIL]: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            axios.get('/get-list-detail/'+ params.listId)
                .then((response) => {
                    let List = response.data.list
                    commit("GET_LIST_SUCCESS", { List });
                    resolve(response);
                })
                .catch((err) => {
                    commit("GET_LIST_ERROR");
                    reject(err);
                });
        });
    },

    [UPDATE_LIST]: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            axios.post('/update-list/'+ params.listId, params.payload)
                .then((response) => {
                    let List = response.data.list
                    commit("UPDATE_LIST_SUCCESS", { List });
                    resolve(response);
                })
                .catch((err) => {
                    commit("GET_USER_LIST_ERROR");
                    reject(err);
                });
        });
    },

    [GET_USER_LIST]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios.get('/get-user-list')
                .then((response) => {
                    let userList = response.data
                    commit("GET_USER_LIST_SUCCESS", { userList });
                    resolve(response);
                })
                .catch((err) => {
                    commit("GET_ALL_LISTS_ERROR");
                    reject(err);
                });
        });
    },

    [ADD_FRIEND_LIST]: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            axios.post('/addFriend-list/'+ params.listId, params.payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    commit("GET_USER_LIST_ERROR");
                    reject(err);
                });
        });
    },
    [GET_FRIEND_LIST]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios.get('/get-friend-list')
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    commit("GET_ALL_LISTS_ERROR");
                    reject(err);
                });
        });
    },
    [ACCEPT_FRIEND_LIST]: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            axios.post('/acceptFriend-list/'+ params.id)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    commit("GET_USER_LIST_ERROR");
                    reject(err);
                });
        });
    },
    [REMOVE_FRIEND_LIST]: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            axios.post('/removeFriend-list/'+ params.id)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    commit("GET_USER_LIST_ERROR");
                    reject(err);
                });
        });
    },
}

const mutations = {
    [GET_ALL_LISTS_SUCCESS]: (state, params) => {
        state.allList = params.allList;
    },

    [GET_USER_LIST_SUCCESS]: (state, params) => {
        state.userList = params.userList;
    },

    [GET_ALL_LISTS_ERROR]: () => {
    },

    [GET_LIST_SUCCESS]: (state, params) => {
        state.list = params.List;
    },

    [GET_LIST_ERROR]: () => {
    },

    [UPDATE_LIST_SUCCESS]: () => {
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
<template>
  <div class="wrapper">
    <div class="menuWrapper" :style="{ right: menuPosition }">
      <Menu />
    </div>
    <!-- <Header title="レビューする" /> -->
    <router-view />
    <img
      src="./assets/images/f2.png"
      class="absolute-img1 absolutes-images"
      v-if="!isLogin"
    />
    <img
      src="./assets/images/f.png"
      class="absolute-img2 absolutes-images"
      v-if="!isLogin"
    />
    <img
      src="./assets/images/f3.png"
      class="absolute-img3 absolutes-images"
      v-if="!isLogin"
    />

    <!-- API Calls Loader -->
    <!-- <v-overlay :value="true">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay> -->
    <!-- END API Calls Loader -->
  </div>
</template>
<script>
import Menu from "./components/Menu";
// import Header from './components/Header'
export default {
  components: {
    Menu,
  },
  computed: {
    isHome() {
      return true;
      //  return this.$route.name == 'Home'
    },
    isLogin() {
      return this.$store.getters.isLoggedIn;
    },
    menuPosition() {
      return this.$store.state.menuPosition;
    },
  },
  watch: {
    $route() {
      this.$store.commit("hideMenu");
    },
  },
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.v-skeleton-loader {
  // position: absolute !important;
  // top: 7.6%;
  width: 23.7%;
  margin: auto;
  // z-index: 99999;
}

.v-skeleton-loader__image {
  height: 74.5vh !important;
}

.full-width {
  max-width: 100% !important;
}

.w-50 {
  width: 50% !important;
}

.container {
  width: 100%;
  padding: 0 !important;
}

.btn:focus {
  outline: none;
}

.v-alert {
  max-width: 350px;
  margin: auto;
  position: fixed !important;
  bottom: 100px;
  left: 0;
  right: 0;
  box-shadow: 0px 10px 10px 1px #78787861 !important;
}

.v-alert.success {
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
}

button.btn-orange {
  background: #f47500 !important;
  color: #ffffff;
}

button.btn-gray {
  background: #cfcfcf !important;
  color: #000000;
}

.v-alert.danger {
  background-color: #ff5252 !important;
  border-color: #ff5252 !important;
}

.image-and-name-container {
  position: relative;
  // background-color: #0000ff36;
  width: 150px;
  height: 100px;
  margin: auto;
  margin-bottom: 90px;
}

.profile-pic-input .v-input__slot {
  // background-color: rgba(255, 0, 0, 0.12);
  width: 84%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  z-index: 999999999;
  height: 86%;
  opacity: 0;
}

.form-control {
  height: unset;
  box-shadow: unset;
  padding: unset;
  -webkit-box-shadow: unset;
  border: unset;
}

.btn {
  margin-left: 0;
  margin-right: 0;
}

.bootstrap-tagsinput {
  border: none !important;
  box-shadow: none;
  width: 100%;

  input {
    border-bottom: 1px solid #b1b1b1;
    width: 100%;
  }
}

.bootstrap-tagsinput .tag {
  margin-right: 10px;
  color: #333333;
  font-size: 14px;
  border: 1px solid #8b8b8b;
  border-radius: 20px;
  padding: 2px 5px;
  font-weight: 400;
  background: transparent;
}

.wrapper {
  min-height: 100vh;
  position: relative;
  background: rgb(234, 237, 240);
  margin: auto;
  overflow: hidden;

  .absolutes-images {
    position: absolute;
  }

  .absolute-img1 {
    top: 130px;
    left: -30px;

    @media (min-width: 1200px) {
      width: 250px;
    }
  }

  .absolute-img2 {
    top: 35%;
    right: -30px;

    @media (min-width: 1200px) {
      width: 250px;
    }
  }

  .absolute-img3 {
    @media (min-width: 1200px) {
      width: 310px;
    }

    @media (max-width: 520px) {
      top: 55%;
    }

    top: 65%;
    left: -30px;
  }

  .menuWrapper {
    transition: all 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    position: absolute;
    top: 0;
    right: 0;
    width: 305px;
    z-index: 1000;
  }
}

/**Helpers */
.fw-700 {
  font-weight: 700;
}

.fw-bold {
  font-weight: bold;
}

.text-white {
  color: white;
}

.search-field {
  background-color: #ffffff;
  padding: 5px;
  height: 47px;
  border-radius: 50px;

  .v-text-field--rounded > .v-input__control > .v-input__slot {
    padding: 0 5px !important;
  }

  .v-autocomplete.v-select.v-input--is-focused input {
    min-width: unset !important;
  }

  .v-autocomplete {
    width: 30% !important;
    max-width: 30% !important;
  }

  .v-text-field {
    padding-top: 0px !important;
    max-width: 40%;

    .v-input__slot:before {
      border-color: none !important;
    }
  }

  // .theme--light.v-text-field>.v-input__control>.v-input__slot:before {
  //   border-color: none !important;
  // }

  .search-icon-section {
    width: 28%;
    background-color: #4893e0;
    align-items: center;
    height: 37px;
    margin-left: 2%;
    border-radius: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
}

.text-orange {
  color: #ff9b3f !important;
}

.bg-orange {
  background-color: #ff9b3f !important;
}

.orange-fill {
  fill: #ff9b3f;
}

.black {
  color: #000;
}

.link {
  text-decoration: none;
}

.link:hover {
  text-decoration: none;
}

.black-stroke {
  stroke: #000 !important;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.justify-center {
  justify-content: center;
}

.flex-column {
  flex-direction: column;
}

.text-center {
  text-align: center;
}

.drop-shadow {
  box-shadow: 0px 4px 15px #000;
}

.text-shadow {
  text-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
}

.img-100 {
  width: 100%;
}

.contained {
  object-fit: contain;
}

.v-messages__message {
  color: red;
  font-weight: bold;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueRouter from 'vue-router';
import store from './store'
import mDatePicker from 'vue-multi-date-picker'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSocialauth from 'vue-social-auth'
import VueAxios from 'vue-axios'
import vuetify from '@/plugins/vuetify'
import axios from 'axios'
import '@/plugins/vee-validate'

Vue.use(VueAxios, axios);

// Google Map Config
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyB8F1I88u1XLOvKUKgZzllskGN9FbBp86o',
    libraries: 'places',
  }
});

// Social Login Config
Vue.use(VueSocialauth, {
  providers: {
    google: {
      clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      client_secret: process.env.VUE_APP_GOOGLE_CLIENT_SECRET,
      redirectUri: process.env.VUE_APP_GOOGLE_REDIRECT_URL
    },
    facebook: {
      clientId: process.env.VUE_APP_FACEBOOK_CLIENT_ID,
      client_secret: process.env.VUE_APP_FACEBOOK_CLIENT_SECRET,
      redirectUri: process.env.VUE_APP_FACEBOOK_REDIRECT_URL
    }
  }
});
Vue.use(VueRouter)
require('@/services/api')

// Vue(App).use(store).use(router).use(mDatePicker).mount('#app')
new Vue({
  router,
  store,
  vuetify,
  mDatePicker,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <v-app>
    <div class="container full-width">
      <Header />
      <div class="content">
        <h3>{{ message }}</h3>
        <div class="row">
          <div class="col-md-4"></div>
          <div class="col-md-4">
            <v-progress-linear
              color="#F47500"
              v-if="loading"
              indeterminate
              rounded
              height="12"
            >
            </v-progress-linear>
          </div>
          <div class="col-md-4"></div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  components: {},
  computed: {},
  data() {
    return {
      message: "Verifying Account.....",
      loading: true,
    };
  },
  methods: {},

  created() {
    let payload = {
      verification_token: this.$route.params.token,
    };
    this.$store
      .dispatch("VERIFY_TOKEN", payload)
      .then(
        (response) => {
          this.loading = false;
          if (response.data.status) this.$router.push("/");
        },
        (error) => {
          this.loading = false;
          throw error;
        }
      )
      .catch((error) => {
        this.loading = false;
        if (error)
          if (error.data.message) this.message = error.data.message.message;
      });
  },
};
</script>

<style lang="scss" scoped>
h3 {
  text-align: center;
  margin-top: 40vh;
}
</style>

<template>
  <div class="container full-width">
    <Header />
    <v-skeleton-loader
      v-if="loading"
      class="mx-auto"
      type="card"
    ></v-skeleton-loader>
    <div class="content" v-if="!loading">
      <div v-for="(feed, index) in feeds" :key="index" class="card">
        <div
          class="top-content flex"
          @click="rediredtToDetailPage($event, feed.id)"
        >
          <div class="user-info flex">
            <img
              class="user-profile-image"
              :src="getImage(feed.user.picture)"
            />
            <div class="name">
              {{ feed.user.name }}
            </div>
          </div>
          <div class="post-time-and-state">
            <div class="time">
              {{ feed.created_at }}
            </div>
            <div class="state">
              {{ getRestaurantGenre(feed.restaurants) }}
            </div>
          </div>
        </div>
        <div
          class="resturant-content"
          @click="rediredtToDetailPage($event, feed.id)"
        >
          <div class="main-image">
            <img class="featured-image" :src="getFeaturedImage(feed.images)" />
            <div
              class="more-images"
              v-if="Object.keys(feed.images).length > 1"
              @click="showMoreImages"
            >
              <img
                src="@/assets/images/morepictures.png"
                class="more-images-icon"
              />
            </div>
          </div>
          <div
            class="name-and-rating flex"
            @click="rediredtToDetailPage($event, feed.id)"
          >
            <div class="name">
              {{ feed.restaurants?.name }}
            </div>
            <star-rating
              v-model:rating="feed.grade"
              :rating="0"
              :max-rating="5"
              :increment="0.5"
              read-only
              inactive-color="#c0c0c0"
              :rounded-corners="true"
              :border-color="null"
              :star-size="20"
            >
            </star-rating>
          </div>
          <div class="comments" @click="rediredtToDetailPage($event, feed.id)">
            <div class="comment">
              <div
                class="comment-text"
                v-for="(comment, commentIndex) in feed.comments"
                :key="commentIndex"
              >
                {{ comment.comment }}
              </div>
            </div>
          </div>
        </div>
        <ImageSlider v-if="showSlider" :images="feed.images" />
      </div>
      <v-skeleton-loader
        v-if="loading"
        class="mx-auto"
        type="card"
      ></v-skeleton-loader>
    </div>

    <div class="footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Footer from "../components/Footer";
import Header from "../components/Header.vue";
import ImageSlider from "../components/ImageSlider.vue";
import StarRating from "vue-star-rating";
export default {
  components: {
    Footer,
    Header,
    ImageSlider,
    StarRating,
  },
  computed: {
    ...mapGetters(["allReviews"]),
  },
  data() {
    return {
      showSlider: false,
      feeds: [],
      loading: true,
      allDataLoaded: false,
      pageNumber: 1,
    };
  },
  mounted() {
    document.addEventListener("click", (elem) => this.hideSliderMenu(elem));
    window.onscroll = function () {
      //  + 1300
      let scroll = window.innerHeight + window.scrollY;
      if (scroll >= document.body.scrollHeight) {
        if (!this.allDataLoaded) {
          this.pageNumber++;
          this.loadData();
        }
      }
    }.bind(this);
  },
  methods: {
    showMoreImages() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let scrollLeft =
        window.pageXOffset || document.documentElement.scrollLeft;
      window.onscroll = function () {
        window.scrollTo(scrollLeft, scrollTop);
      };
      this.showSlider = true;
    },
    hideSliderMenu(elementtt) {
      let sliderArea = elementtt.target.closest(".slider-images");
      let popSliderIcons =
        elementtt.target.classList.contains("more-images-icon");
      if (!sliderArea && this.showSlider == true && popSliderIcons == false) {
        this.showSlider = false;
        window.onscroll = function () {};
      }
    },

    rediredtToDetailPage(event, reviewId) {
      if (event.target.attributes.class.nodeValue != "more-images-icon") {
        this.$router.push({ name: "Review", params: { id: reviewId } });
      }
    },

    getImage(image) {
      return process.env.VUE_APP_URL + image;
    },

    getRestaurantGenre(restaurant) {
      let allGenres = restaurant?.restaurant_genres
        ? restaurant.restaurant_genres.map((item) => item.name)
        : [];
      return allGenres.toString();
    },

    getFeaturedImage(images) {
      let image = images.filter((x) => x.featured)[0];
      if (image) {
        return process.env.VUE_APP_URL + image.image;
      } else {
        return require("@/assets/images/image3.png");
      }
    },

    updateFeed(response) {
      for (let i = 0; i < response.length; i++) {
        this.feeds.push(response[i]);
      }
    },

    loadData() {
      this.$store
        .dispatch("GET_ALL_REVIEWS", { page: this.pageNumber })
        .then((response) => {
          if (!response.length) {
            this.allDataLoaded = true;
          } else {
            this.updateFeed(response);
          }
          this.loading = false;

          (error) => {
            this.loading = false;
            throw error;
          };
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },

  created() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.container {
  .footer {
    max-width: 375px;
    margin: auto;
  }

  .user-profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50px;
  }

  .featured-image {
    width: 100%;
  }

  .vue-star-rating-star svg {
    width: 20px;
  }

  margin: auto;

  .top-bar {
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 15px 12px;

    .logo,
    .menu {
      cursor: pointer;
    }

    .menu {
      position: fixed;
      top: 20px;
      right: 10px;
    }
  }

  .content {
    max-width: 375px;
    margin: auto;
    padding-bottom: 80px;

    //  margin-top: 20px !important;
    @media (max-width: 375px) {
      margin-top: 0 !important;
    }

    .card {
      background: #fff;
      margin-bottom: 5px;
      padding-bottom: 20px;
    }

    .top-content {
      padding: 12px;
      justify-content: space-between;

      .user-info {
        align-items: center;
        gap: 15px;

        .name {
          font-size: 15px;
        }
      }

      .post-time-and-state {
        .time {
          font-size: 13px;
          font-weight: 300;
          color: #888888;
          font-style: italic;
        }

        .state {
          font-size: 15px;
          color: #6c6c6c;
        }
      }
    }

    .resturant-content {
      .main-image {
        position: relative;

        .more-images {
          position: absolute;
          bottom: 0;
          right: 10px;
          cursor: pointer;
        }
      }

      .name-and-rating {
        padding: 12px;
        justify-content: space-between;

        .name {
          font-size: 15px;
          color: #6c6c6c;
        }

        .rating {
          .star {
            div {
              img {
                width: 20px;
              }
            }
          }
        }
      }

      .comments {
        padding: 0 12px;

        .comment {
          font-size: 15px;
          color: #6c6c6c;
        }
      }
    }
  }
}
</style>
<template>
    <div class="container full-width">
    <div>
    <Header title="Password" />
          <div class="content">
          <div class="box">
             <form @submit.prevent = "submit" >
                 <div class="form-control">
                     <label>Current Password</label>
                     <input type="text" placeholder="Enter your Password"  v-model="password">
                 </div>
                 <div class="form-control">
                     <label>New Password</label>
                     <input type="password" placeholder="Enter your email address"  v-model="newPassword">
                 </div>
                 <div class="submit-btn flex justify-center">
                 <button class="btn white" :class="{'bg-orange':orangeBackground}">更新する</button>
                 </div>
             </form>
          </div>
          </div>
      </div>
      <div class="footer">
          <Footer />
         
      </div>
    </div>
</template>

<script>
import Footer from '../components/Footer.vue'
import Header from '../components/Header.vue'
export default {
components:{
    Footer ,  Header
},
computed:{
},
data(){
    return{
        password: 'password1234',
        newPassword : '',
        orangeBackground : false
    }
},
watch:{
    newPassword(){
    this.newPassword != '' ? this.orangeBackground = true : this.orangeBackground = false
    }
},

 methods:{
        back(){
            this.$router.go(-1)
        },
    }
}
</script>

<style lang="scss" scoped>
.container{
    .footer{
        max-width: 375px;
        margin: auto;
    }
    .content{
        max-width: 375px;
        margin: auto;
        background: #e5e5e5;
        padding: 20px;
        .box{
            background: #FFFFFF;
            border: 1px solid #EAEAEA;
            box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
            border-radius: 5px;
            padding: 30px 15px;
            .form-control{
                &:nth-child(2){
                    margin-top: 20px;
                }
                label{
                    font-size: 14px;
                    color: #F47500;
                    display: block;
                    padding-bottom: 10px;
                }
                input{
                    border: none;
                    &:focus{
                        outline: none;
                    }
                    margin-bottom:10px;
                    border-bottom: 1px solid #B1B1B1;
                    padding-bottom: 10px;
                    width: 100%;
                }
            }
            .submit-btn{
                .btn{
                  margin-top: 20px;
                  cursor: pointer;
                  border: none;
                  border-radius: 5px;
                  width: 75%;
                  padding: 10px;
                  background: #cfcfcf;
                }
            }
        }
    }
}
</style>
<template>
    <div class="menu">
        <div class="alert alert-danger alert-dismissible show" role="alert" v-if="this.error && this.alertType == 'danger'">
            {{ error }}
        </div>

        <div class="alert alert-success alert-dismissible show" role="alert" v-if="this.error && this.alertType == 'success'">
            {{ error }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'Alerts',
    props: ['error', 'alertType'],
    data() {
        return {
        }
    },
    methods: {
    },
    created() {
    }
}
</script>

<style lang="scss" scoped>
</style>
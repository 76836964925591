import axios from 'axios'
import store from '@/store'

const authClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL
  // withCredentials: true // required to handle the CSRF token
})

/*
 * Add a response interceptor
 */
authClient.interceptors.response.use(
  response => {
    return response
  },
  function(error) {
    if (
      error.response &&
      [401, 419].includes(error.response.status) &&
      store.getters['user']
    ) {
      store.commit('AUTH_LOGOUT')
    }
    return Promise.reject(error)
  }
)

export default {
  async login(user) {
    // await authClient.get('/sanctum/csrf-cookie')
    return authClient.post('login', user)
  },
  logout() {
    return authClient.post('logout')
  },
  getUser() {
    return axios.get('user')
  },
  async sendResetLink(email) {
    // await authClient.get('/sanctum/csrf-cookie')
    return authClient.post('/auth/password/email', email)
  },
  async reset(params) {
    // await authClient.get('/sanctum/csrf-cookie')
    return authClient.post('/auth/password/reset', params)
  },
  changePassword(params) {
    return axios.put('change-password', params)
  }
}

<template>
  <div class="container full-width g">
    <Header title="Map" />
    <v-skeleton-loader
      v-if="loading"
      class="mx-auto"
      type="card"
    ></v-skeleton-loader>

    <div class="content" v-if="!loading && this.userList.list.length">
      <div class="map">
        <GoogleMap
          :selectedList="this.selectedList"
          :userList="this.userList"
          @restaurantSelected="restaurantSelected"
        />
        <div class="listname-and-filter flex">
          <div class="listname flex">
            <img src="@/assets/images/marker.png" class="contained" />
            <div class="name">{{ selectedList.name }}</div>
            <img
              src="@/assets/images/dropdown.png"
              @click="toggleListModal"
              style="cursor: pointer"
              class="contained"
            />
          </div>
          <div class="filter">
            <img :src="require('@/assets/images/sort-map.svg').default" />
          </div>
        </div>
        <div class="resturant">
          <div
            class="hide-menu flex justify-center"
            @click="toggleResturantsContent"
          >
            <img src="@/assets/images/hideline.png" />
          </div>
          <div v-if="resturantContent" class="hideandshowcontent">
            <div class="image-and-content flex">
              <div class="featured-image">
                <img :src="getFeaturedImage(selectedReview.images)" />
              </div>
              <div class="resturant-content">
                <div class="rating-and-name flex">
                  <div class="rating white fw-700 text-center">
                    {{ selectedReview?.grade }}
                  </div>
                  <div class="name">
                    {{ selectedReview?.restaurants?.name }}
                  </div>
                </div>
                <div class="images-and-texts flex">
                  <div class="images flex flex-column">
                    <div class="category-image">
                      <img src="@/assets/images/knifeporkspoon.png" />
                    </div>
                    <div class="location-image">
                      <img src="@/assets/images/location.png" />
                    </div>
                    <div class="comment-image">
                      <img src="@/assets/images/comment.png" />
                    </div>
                  </div>
                  <div class="texts flex flex-column">
                    <div class="category-text flex">
                      <div>
                        {{
                          getGenres(
                            selectedReview?.restaurants?.restaurant_genres
                          )
                        }}
                      </div>
                      <div class="flex camera-content">
                        <img
                          src="@/assets/images/camera.png"
                          class="contained"
                        />
                        <div>{{ selectedReview?.images.length }}</div>
                      </div>
                    </div>
                    <div class="location-text">
                      {{ getAddress(selectedReview?.restaurants) }}
                    </div>
                    <div
                      class="comment-text"
                      v-for="(comment, commentIndex) in selectedReview.comments"
                      :key="commentIndex"
                    >
                      {{ comment.comment }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="arrow left-arrow" @click="changeResturant('previous')">
              <img src="@/assets/images/leftarrow.png" />
            </div>
            <div class="arrow right-arrow" @click="changeResturant('next')">
              <img src="@/assets/images/rightarrow.png" />
            </div>
          </div>
        </div>
      </div>
      <ListNames
        v-if="showListModal"
        v-on:closelist="toggleListModal"
        @listSelected="listSelected"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GoogleMap from "../components/GoogleMap.vue";
import ListNames from "../components/ListNames.vue";
import Header from "../components/Header";
export default {
  components: {
    GoogleMap,
    ListNames,
    Header,
  },
  computed: {
    Locations() {
      return this.$store.getters.getLocations;
    },
    ...mapGetters(["userList"]),
  },
  created() {
    this.$store
      .dispatch("GET_USER_LIST")
      .then((response) => {
        console.log(response);
        this.loading = false;
        (error) => {
          this.loading = false;
          throw error;
        };
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
      });
    this.selectedList =
      this.userList?.list?.length > 0 ? this.userList.list[0] : {};
    if (this.selectedList && this.userList.list.length) {
      if (this.$route.query.list) {
        let listId = Number(this.$route.query.list);
        let reviewId = Number(this.$route.query.review);
        this.selectedListIndex = this.userList.list.findIndex(
          (list) => list.id == listId
        );
        this.selectedReviewIndex = this.userList.list[
          this.selectedListIndex
        ].reviews.findIndex((review) => review.id == reviewId);
        this.listSelected(this.selectedListIndex, false);
      } else {
        this.listSelected("0");
      }
    }
  },
  data() {
    return {
      scrollPosition: null,
      listName: "",
      resturantContent: false,
      selectedReview: {},
      selectedReviewIndex: 0,
      showListModal: false,
      currentResturant: 0,
      selectedListIndex: 0,
      reviews: null,
      resturants: [],
      selectedList: {},
      loading: true,
    };
  },
  methods: {
    toggleResturantsContent() {
      this.resturantContent = !this.resturantContent;
    },

    toggleListModal() {
      this.showListModal = !this.showListModal;
    },

    changeResturant(Resturant) {
      if (Resturant == "previous") {
        if (
          this.userList.list[this.selectedListIndex]?.reviews[
            this.selectedReviewIndex - 1
          ]
        ) {
          this.selectedReviewIndex--;
          this.selectedReview =
            this.userList.list[this.selectedListIndex]?.reviews[
              this.selectedReviewIndex
            ];
        }
      } else if (Resturant == "next") {
        if (
          this.userList.list[this.selectedListIndex]?.reviews[
            this.selectedReviewIndex + 1
          ]
        ) {
          this.selectedReviewIndex++;
          this.selectedReview =
            this.userList.list[this.selectedListIndex]?.reviews[
              this.selectedReviewIndex
            ];
        }
      }
    },

    listSelected(selectedListIndex, openPopup = true) {
      this.selectedListIndex = selectedListIndex;
      this.selectedList = this.userList.list[selectedListIndex];
      if (openPopup) {
        this.showListModal = !this.showListModal;
        this.selectedReviewIndex = 0;
      }
      this.selectedReview =
        this.userList.list[this.selectedListIndex]?.reviews[
          this.selectedReviewIndex
        ];
      this.resturantContent = this.userList ? true : false;
    },

    restaurantSelected(reviewIndex) {
      this.resturantContent = this.userList ? true : false;
      this.selectedReviewIndex = reviewIndex;
      this.selectedReview =
        this.userList.list[this.selectedListIndex]?.reviews[reviewIndex];
    },

    getGenres(genres) {
      let allGenres = genres ? genres.map((item) => item.name) : [];
      return allGenres.toString();
    },

    getAddress(restaurant) {
      return (
        restaurant?.postalcode +
        " " +
        restaurant?.prefecture?.ja_name +
        restaurant?.town +
        restaurant?.address
      );
    },

    getFeaturedImage(images) {
      let image = images.filter((x) => x.featured)[0];
      if (image) {
        return process.env.VUE_APP_URL + image.image;
      } else {
        return require("@/assets/images/image3.png");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .content {
    max-width: 375px;
    margin: auto;
    height: 100vh;

    .featured-image {
      width: 100px;
      display: flex;

      img {
        width: 100px;
        margin: auto;
      }
    }

    .map {
      position: relative;

      .listname-and-filter {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 95%;
        justify-content: space-between;

        .listname {
          gap: 5px;
          background: #ffffff;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 30px;
          padding: 10px;
        }
      }

      .resturant {
        padding: 10px;
        background: #ffffff;
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 25px 25px 0px 0px;
        position: relative;
        position: absolute;
        bottom: 0;
        width: 100%;

        .hideandshowcontent {
          transition: 2s;
        }

        .arrow {
          cursor: pointer;
          position: absolute;
          top: 50%;
        }

        .left-arrow {
          left: 10px;
        }

        .right-arrow {
          right: 10px;
        }

        .hide-menu {
          cursor: pointer;
        }

        .image-and-content {
          margin-top: 10px;
          gap: 10px;

          .resturant-content {
            .rating-and-name {
              gap: 10px;

              .rating {
                font-size: 18px;
                font-style: italic;
                padding: 6px;
                border-radius: 50%;
                background: linear-gradient(
                  224.31deg,
                  #f57600 19.14%,
                  #ffb82f 80.17%
                );
                min-width: 40px;
              }

              .name {
                font-size: 18px;
                color: #333333;
              }
            }

            .images-and-texts {
              margin-top: 10px;
              gap: 10px;

              .images {
                align-items: center;
              }

              .texts {
                .category-text,
                .location-text,
                .comment-text {
                  font-size: 15px;
                  color: #838383;
                }

                .category-text {
                  justify-content: space-between;

                  .camera-content {
                    gap: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<template>
    <div class="container full-width"> 
     <Header title="Share List" />
    <div class="content">
        <div class="outer-box">
            <div class="content-box">
            <div class="image flex justify-center">
                <img src="@/assets/images/sharelistimage.png" />
            </div>
            <div class="text">
              <div class="title">あなたのリストをSNSやメールでシェアしましょう!</div>
              <div class="desc">あなたのリストをSNSやメールでシェアしましょう!</div>
          </div>
          <div class="submit-button">
              <button class="submit-btn white">リンクを作成する</button>
          </div>
            </div>
        </div>
    </div>
      <div class="footer">
          <Footer />
         
      </div>
    </div>
</template>

<script>
import Footer from '../components/Footer.vue'
import Header from '../components/Header.vue'
export default {
components:{
    Footer , Header
},
}
</script>

<style lang="scss" scoped>

.container{
    .footer{
        max-width: 375px;
        margin: auto;
    }
    .content{
        max-width: 375px;
        margin: auto;
        .outer-box{
            // background: #e5e5e5;
            padding: 15px;
            height: 100vh;
            .content-box{
                background: #FFFFFF;
                border: 1px solid #EAEAEA;
                box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
                border-radius: 5px;
                padding: 40px;
                .text{
                    .title{
                        margin-top:20px;
                        color: #333;
                        font-size: 16px;
                        padding: 0 10px;
                    }
                    .desc{
                        margin-top: 10px;
                        font-size: 14px;
                        color: #5b5b5b;
                        padding: 0 15px;
                    }
                }
                 .submit-button{
                        .submit-btn{
                            background: #f47500;
                            padding: 10px 0;
                            border: none;
                            border-radius: 5px;
                            display: block;
                            width: 100%;
                            margin-top: 20px;
                        }
                    }
            }
        }
    }
   
}
</style>
<template>
  <div>
    <Login v-if="login" class="modals-padding" />
    <Signup1 v-if="signup1" class="modals-padding" />
    <EmailSignup v-if="emailSignup" class="modals-padding" />
    <div class="outer-container">
      <div class="container">
        <div class="header flex">
          <div class="logo">
            <img src="../assets/images/logo.png">
          </div>
          <div class="nav" @click="enableMenu">
            <img src="../assets/images/Menu.png">
          </div>
        </div>
        <div class="body flex">
          <div class="flex-1 flex justify-center">
            <div>
              <div class="title white fw-700 text-center text-shadow">
                自分だけの<br>
                レストランリスト
              </div>
              <div class="description white text-center text-shadow">
                Food review sharing app!
              </div>
              <div class="buttons flex">
                <button class="btn login-btn fw-700" type="button" @click="setLogin">Login</button>
                <button class="btn signup-btn white fw-700" @click="setSignup1">Sign Up</button>
              </div>
            </div>
          </div>
          <div class="flex-1 flex justify-center iphonex">
            <img src="../assets/images/iphone.png">
          </div>
        </div>
        <div class="footer">
          <img src="../assets/images/logo.png">
          <div class="flex footer-links">
            <div class="about-us">
              <div class="title white fw-bold text-shadow">
                About us
              </div>
              <div class="links flex flex-column">
                <router-link :to="{ name: '' }" class="link text-shadow">トーストとは？</router-link>
                <router-link :to="{ name: '' }" class="link text-shadow">アプリ</router-link>
                <router-link :to="{ name: '' }" class="link text-shadow">FAQs</router-link>
                <router-link :to="{ name: '' }" class="link text-shadow">運営会社</router-link>
              </div>
            </div>
            <div class="resources">
              <div class="title white fw-bold">
                Resources
              </div>
              <div class="links flex flex-column">
                <router-link :to="{ name: '' }" class="link ">Terms of Service</router-link>
                <router-link :to="{ name: '' }" class="link">Privacy Policy</router-link>
              </div>
            </div>
            <div class="get-in-touch">
              <div class="title white fw-bold">
                Get in Touch<br> フィードバック
              </div>
              <div class="copyright flex white">
                <div class="text-shadow"> @2021 Toast運営事務局 </div>
                <div class="text-shadow"> クッキーについて </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Login from '../components/Login.vue'
import Signup1 from '../components/Signup1.vue'
import EmailSignup from '../components/EmailSignup.vue'
export default {

  name: 'Home',
  components: {
    Login, Signup1, EmailSignup
  },
  mounted() {
    document.addEventListener('click', elem => this.hideLogin(elem))
  },
  computed: {
    login() {
      return this.$store.state.loginModal
    },
    signup1() {
      return this.$store.state.signup1Modal
    },
    emailSignup() {
      return this.$store.state.emailSignup
    },
  },
  data() {
    return {

    }
  },
  methods: {
    setLogin() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop
      let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
      window.onscroll = function () {
        window.scrollTo(scrollLeft, scrollTop);
      };
      this.$store.commit('setLoginModal')
    },
    setSignup1() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop
      let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
      window.onscroll = function () {
        window.scrollTo(scrollLeft, scrollTop);
      };
      this.$store.commit('setSignup1Modal')
    },
    enableMenu() {
      this.$store.commit('toggleMenu')
    },
    hideLogin(elementtt) {
      if (this.login) {
        let sliderArea = elementtt.target.closest('.box')
        let popSliderIcons = elementtt.target.classList.contains('login-btn')
        if (!sliderArea && this.$store.state.loginModal == true && popSliderIcons == false) {
          this.$store.commit('setLoginModal')
          window.onscroll = function () { }
        }
      }
      if (this.signup1) {
        let sliderArea = elementtt.target.closest('.box')
        let popSliderIcons = elementtt.target.classList.contains('signup-btn')
        if (!sliderArea && this.$store.state.signup1Modal == true && popSliderIcons == false) {
          this.$store.commit('setSignup1Modal')
          window.onscroll = function () { }
        }
      }
      if (this.emailSignup) {
        let sliderArea = elementtt.target.closest('.box')
        let popSliderIcons = elementtt.target.classList.contains('email')
        if (!sliderArea && this.$store.state.emailSignup == true && popSliderIcons == false) {
          this.$store.commit('setEmailSignup')
          window.onscroll = function () { }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.modals-padding {
  padding: 20px;
}

.outer-container {
  width: 100%;
  background: linear-gradient(188.59deg, #F47500 27.77%, #FF9900 77.94%);
}

.container {
  position: relative;
  padding: 20px !important;
  width: 100%;
  max-width: 980px;
  min-height: 100vh;

  .header {
    justify-content: space-between;
    align-items: center;

    div {
      cursor: pointer;
    }
  }

  .body {
    @media(max-width:520px) {
      flex-direction: column;

      .iphonex {
        margin-top: 120px;
      }
    }

    position: relative;
    z-index: 100;
    margin-top: 50px;
    align-items: center;

    .title {
      font-size: 30px;
      line-height: 40.1px;
      margin-top: 50px;
    }

    .description {
      font-size: 18px;

      @media(min-width:768px) {
        font-size: 28px;
        margin-top: 30px;
      }

      font-weight: 500;
      margin-top: 20px;
      line-height: 24.01px;
    }

    .buttons {
      @media(max-width:520px) {
        flex-direction: column;
        align-items: center;
        margin-top: 55px;
        gap: 30px;
      }

      margin-top: 20px;

      @media(min-width:768px) {
        margin-top: 40px;
      }

      justify-content: center;
      gap:15px;

      .btn {
        cursor: pointer;
        border-radius: 50px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border: none;
        width: 182px;
        padding: 10px;
        font-size: 15px;

        @media(min-width:768px) {
          font-size: 22px;
          letter-spacing: 3px;
        }

        transition: .5s;
      }

      .login-btn {
        background: #fff;

        &:hover {
          background: transparent;
          border: 2px solid #fff;
          color: #fff;
        }
      }

      .signup-btn {
        background: transparent;
        border: 2px solid #fff;

        &:hover {
          background: #fff;
          color: initial;
        }
      }
    }
  }

  .footer {
    margin-top: 50px;

    @media(max-width:520px) {
      margin-top: 150px;
    }

    position: relative;
    z-index: 100;

    .footer-links {
      gap: 50px;
      flex-wrap: wrap;

      @media(min-width:768px) {
        gap: 100px;
      }
    }

    .link {
      text-decoration: none;
      color: #fff;
      font-size: 14px;

      @media(min-width:768px) {
        font-size: 18px;
      }

      font-weight: 700;
      margin-top: 10px;

      &:first-child {
        margin-top: 0 !important;
      }
    }

    .about-us,
    .resources,
    .get-in-touch {
      .title {
        margin: 10px 0;
        font-size: 18px;

        @media(min-width:768px) {
          font-size: 24px;
        }
      }
    }

    .get-in-touch {

      // margin-top: 20px;
      .copyright {
        font-size: 12px;
        gap: 20px
      }
    }
  }

  .iphonex {
    img {
      width: 100%;
    }
  }
}
</style>

<template>
  <div class="overlay">
    <div class="box flex">
      <div class="close-btn flex" @click="setLogin">
        <img src="@/assets/images/close.png" />
      </div>
      <div class="left flex flex-column justify-center">
        <div class="justify-center logo text-center">
          <img src="../assets/images/logo1.png" />
        </div>
        <div class="description text-center white">アカウントへログイン</div>
      </div>
      <div class="right">
        <div class="title text-center">Login to Toast</div>
        <Alerts v-if="error" :error="error" :alertType="alertType" />
        <validation-observer ref="observer" v-slot="{}">
          <form @submit="login" method="post">
            <div class="inputs flex flex-column">
              <div class="email">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="email"
                >
                  <input
                    type="email"
                    placeholder="メールアドレス *"
                    v-model="email"
                    hide-details="auto"
                    name="email"
                  />
                  {{ errors[0] }}
                </validation-provider>
                <div>
                  <img src="@/assets/images/mail.png" />
                </div>
              </div>
              <div class="password">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="password"
                >
                  <input
                    type="password"
                    placeholder="パスワード *"
                    v-model="password"
                    name="password"
                  />
                  {{ errors[0] }}
                </validation-provider>
                <div>
                  <img src="@/assets/images/lock.png" />
                </div>
              </div>

              <div class="login">
                <button class="btn login-btn textwhite" type="submit">
                  Login
                </button>
              </div>
            </div>
          </form>
        </validation-observer>
        <div class="or-login-with text-center">Or login with</div>
        <div class="logins flex flex-column">
          <div class="google btn flex">
            <img src="@/assets/images/google.png" />
            <div class="text">
              <a @click.prevent="authProvider('google')">Login with Google</a>
            </div>
          </div>
          <div class="facebook btn flex">
            <img src="@/assets/images/fb.png" />
            <div class="text">
              <a @click.prevent="authProvider('facebook')"
                >Login with Facebook</a
              >
            </div>
          </div>
          <div class="line btn flex">
            <img src="@/assets/images/line.png" />
            <div class="text">
              <a @click.prevent="authProvider('line')">Login with Line</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Alerts from "./ui/Alerts";
export default {
  name: "Login",
  components: {
    Alerts,
  },
  data() {
    return {
      showPassword: false,
      email: "",
      password: "",
      isValid: true,
      isLoading: false,
      error: null,
      alertType: null,
      lineCode: null,
      loading: false,
    };
  },
  methods: {
    setLogin() {
      this.$store.commit("setLoginModal");
      window.onscroll = function () {};
    },

    login(event) {
      event.preventDefault();
      if (this.loading) {
        return;
      }
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return;
        }
        this.loading = true;
        const payload = {
          email: this.email,
          password: this.password,
        };
        this.$store
          .dispatch("AUTH_REQUEST", payload)
          .then(
            (response) => {
              this.loading = false;
              if (response.data.status) {
                if (this.$route.query.redirect) {
                  this.$router.push(this.$route.query.redirect);
                } else {
                  this.$router.push("/");
                }
              }
            },
            (error) => {
              this.loading = false;
              throw error;
            }
          )
          .catch((error) => {
            this.loading = false;
            if (error)
              if (error.response.data.errors)
                this.error =
                  "ログインできません。アカウント情報をご確認ください。";
            this.alertType = "danger";
          });
      });
    },

    authProvider(provider) {
      if (this.loading) {
        return;
      }
      if (provider == "line") {
        location.replace(
          "https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1657343095&redirect_uri=" +
            process.env.VUE_APP_LINE_REDIRECT_URL +
            "&state=" +
            Math.random().toString(36).substr(2) +
            "&scope=profile openid email"
        );
      } else {
        this.$auth
          .authenticate(provider)
          .then((response) => {
            this.socialLogin(provider, response);
          })
          .catch((err) => {
            this.loading = false;
            console.log({ err: err });
          });
      }
    },

    socialLogin(provider, response) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const payload = {
        provider: provider,
        response: response,
      };
      this.$store
        .dispatch("AUTH_SOCIAL_REQUEST", payload)
        .then(
          (res) => {
            this.loading = false;
            if (res.data.status) {
              if (this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect);
              } else {
                this.$router.push("/");
              }
            }
          },
          (error) => {
            this.loading = false;
            throw error;
          }
        )
        .catch((error) => {
          this.loading = false;
          if (error)
            if (error.response.data.errors)
              this.error =
                "ログインできません。アカウント情報をご確認ください。";
          this.alertType = "danger";
        });
    },

    loginWithLine() {
      if (this.$route.query.code) {
        if (this.loading) {
          return;
        }
        this.loading = true;
        this.lineCode = this.$route.query.code;
        const payload = {
          provider: "line",
          line_code: this.lineCode,
        };
        this.$store
          .dispatch("AUTH_LINE_LOGIN", payload)
          .then(
            (response) => {
              this.loading = false;
              if (response.data.status) {
                if (this.$route.query.redirect) {
                  this.$router.push(this.$route.query.redirect);
                } else {
                  this.$router.push("/");
                }
              }
            },
            (error) => {
              this.loading = false;
              throw error;
            }
          )
          .catch((error) => {
            this.loading = false;
            if (error)
              if (error.response.data.errors)
                this.error =
                  "ログインできません。アカウント情報をご確認ください。";
            this.alertType = "danger";
          });
      }
    },
  },

  created() {
    this.loginWithLine();
  },
};
</script>
<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    @media (max-width: 520px) {
      flex-direction: column;
      background: #fff;
      border-radius: 20px;
    }

    width: 100%;
    max-width: 980px;
    height: 70vh;
    border-radius: 50px;
    position: relative;

    .close-btn {
      position: absolute;
      right: 40px;
      top: 20px;
      cursor: pointer;

      @media (max-width: 375px) {
        right: 30px;
      }
    }

    .left {
      @media (max-width: 520px) {
        background: unset;
        border-radius: 0;
        margin-right: 0;
        padding: 0;
      }

      border-radius: 20px;
      margin-right: -20px;
      flex-basis: 40%;
      background: linear-gradient(188.59deg, #f47500 27.77%, #ff9900 77.94%);
      padding: 20px;

      .logo img {
        width: 50%;

        @media (max-width: 520px) {
          margin-top: 50px;
        }
      }

      .description {
        @media (max-width: 520px) {
          color: #999999;
        }

        font-size: 16px;
        margin-top: 20px;
      }
    }

    .right {
      @media (max-width: 520px) {
        background: unset;
        border-radius: 0;
      }

      border-radius: 20px;
      padding: 20px;
      background: #fff;
      flex-basis: 60%;

      .title {
        @media (max-width: 520px) {
          display: none;
          margin-top: 0;
        }

        margin-top: 20px;
        font-size: 30px;
      }

      .inputs {
        align-items: center;
        gap: 12px;
        margin: auto;
        margin-top: 20px;
        width: 60%;

        @media (max-width: 520px) {
          width: 100%;
        }

        input {
          width: 100%;

          @media (max-width: 520px) {
            width: 100%;
          }

          height: 36px;
          background: #ffffff;
          border: 1px solid #adadad;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          padding-left: 40px;

          &:focus {
            outline: none;
          }
        }

        .email,
        .password {
          width: 100%;

          @media (max-width: 520px) {
            width: 100%;
          }

          position: relative;

          div {
            height: 100%;
            left: 12px;
            position: absolute;
            display: flex;
            align-items: center;
            top: 0;
          }
        }
      }

      .forgot-password {
        font-size: 12px;
        color: #adadad;
        justify-content: flex-end;
        cursor: pointer;
      }

      .or-login-with {
        margin: 10px 0;
        font-size: 12px;
        color: #999999;
      }

      .login {
        width: 100%;

        .btn {
          background: #f47500;
          border-radius: 50px;
          border: none;
        }

        .login-btn {
          width: 100%;
          padding: 10px 0;
        }
      }

      .logins {
        align-items: center;

        .google,
        .facebook,
        .line {
          align-items: center;
        }

        .btn {
          margin-top: 10px;

          &:first-child {
            margin-top: 0 !important;
          }

          cursor: pointer;
          gap: 10px;
          background: #ffffff;
          border: 1px solid #adadad;
          box-sizing: border-box;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 50px;
          padding: 10px;
          width: 60%;

          @media (max-width: 520px) {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  required,
  email,
  max,
  min,
  size,
  max_value,
  min_value,
  confirmed,
  integer
} from 'vee-validate/dist/rules.umd'

extend('required', {
  ...required,
  message: 'This field is required'
})

extend('integer', {
  ...integer,
  message: 'field_integer_message'
})

extend('max', {
  ...max,
  message: 'field_max_message'
})

extend('min', {
  ...min,
  message: 'field_min_message'
})

extend('faq_max_value', {
  ...max,
  message: 'field_faq_max_value_message'
})

extend('size', {
  ...size,
  message: 'field_size_message'
})

extend('email', {
  ...email,
  message: 'Invalid Email Format'
})

extend('min_value', {
  ...min_value,
  message: 'field_min_value_message'
})

extend('max_value', {
  ...max_value,
  message: 'field_max_value_message'
})


extend('password_confirmed', {
  ...confirmed,
  message: 'field_password_confirmed_message'
})

extend('verify_password', {
  message: 'field_verify_password_message',
  validate: value => {
    var strongRegex = new RegExp(
      '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
    )
    let length = value.length
    return strongRegex.test(value) && length >= 8 && length <= 20
  }
})

extend('only_english_lang_allowed', {
  message: `Only english charactor's are allowed`,
  validate: value => {
    // validation allowing only english language
    var strongRegex = new RegExp(`[^\u0000-\u0080]+`)
    return !strongRegex.test(value)
  }
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

import axios from "axios";

import {
    GET_ALL_GENERES,
    GET_ALL_GENERES_SUCCESS,
    GET_ALL_GENERES_ERROR,
    GET_GENRE,
    GET_GENRE_SUCCESS,
    GET_GENRE_ERROR,
} from "@/store/actions/genres";

function initialState() {
    return {
        allGenres: [],
        genre: {}
    }
}

const state = initialState()

const getters = {
    allGenres: (state) => state.allGenres,
    Genre: (state) => state.genre,
};

const actions = {
    [GET_ALL_GENERES]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios.get('/get-all-genres')
                .then((response) => {
                    let allGenres = response.data.genres
                    commit("GET_ALL_GENERES_SUCCESS", { allGenres });
                    resolve(response);
                })
                .catch((err) => {
                    commit("GET_ALL_GENERES_ERROR");
                    reject(err);
                });
        });
    },

    [GET_GENRE]: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            axios.get('/get-user-list-detail/' + params.listId)
                .then((response) => {
                    let List = response.data.list
                    commit("GET_GENRE_SUCCESS", { List });
                    resolve(response);
                })
                .catch((err) => {
                    commit("GET_GENRE_ERROR");
                    reject(err);
                });
        });
    },
}

const mutations = {
    [GET_ALL_GENERES_SUCCESS]: (state, params) => {
        state.allGenres = params.allGenres;
    },

    [GET_ALL_GENERES_ERROR]: () => {
    },

    [GET_GENRE_SUCCESS]: (state, params) => {
        state.genre = params.genre;
    },

    [GET_GENRE_ERROR]: () => {
    },

};

export default {
    state,
    getters,
    actions,
    mutations,
};
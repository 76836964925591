import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import MyProfile from '../views/MyProfile.vue'
import TopFeed from '../views/TopFeed.vue'
import VerifyAccount from '../views/VerifyAccount.vue'
import List from '../views/List.vue'
import IndividualList from '../views/IndividualList'
import IndividualMap from '../views/IndividualMap'
import ReviewDetail from '../views/ReviewDetail'
import Email from '../views/Email'
import Password from '../views/Password'
import FriendComments from '../views/FriendComments'
import NewReview from '../views/NewReview'
import EditReview from '../views/EditReview'
import FriendRequest from '../views/FriendRequest'
import FriendRequestDetail from '../views/FriendRequestDetail'
import EditList from '../views/EditList'
import ShareList from '../views/ShareList'
import ShareLink from '../views/ShareLink'
import ShareReviews from '../views/ShareReviews'
import FriendList from '../views/FriendList'
import SearchFriend from '../views/SearchFriend'
import AddBySNSorEmail from '../views/AddBySNSorEmail'
import AddFriendbyQrCode from '../views/AddFriendbyQrCode'
import FriendInvites from '../views/FriendInvites'
import store from '@/store/index';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Home,
    meta: {
      public: true, // public => can access those pages without login
      guest: true, // guest => can only access those as a guest (like login page)
    }
  },
  {
    path: '/verify-account/:token',
    name: 'VerifyAccount',
    component: VerifyAccount,
    meta: {
      public: true, // public => can access those pages without login
      guest: true, // guest => can only access those as a guest (like login page)
    }
  },
  {
    path: '/',
    name: 'TopFeed',
    component: TopFeed
  },
  {
    path: '/myprofile',
    name: 'Profile',
    component: MyProfile
  },
  {
    path: '/myprofile/email',
    name: 'Email',
    component: Email
  },
  {
    path: '/myprofile/password',
    name: 'Password',
    component: Password
  },
  {
    path: '/list',
    name: 'List',
    component: List
  }, {
    path: '/list/:id',
    name: 'IndividualList',
    component: IndividualList
  },
  {
    path: '/map',
    name: 'Map',
    component: IndividualMap
  }, {
    path: '/review/:id',
    name: 'Review',
    component: ReviewDetail
  },
  {
    path: '/friendcomments',
    name: 'FriendComments',
    component: FriendComments
  },
  {
    path: '/newreview',
    name: 'NewReview',
    component: NewReview
  },
  {
    path: '/editreview/:id',
    name: 'EditReview',
    component: EditReview
  },
  {
    path: '/frienddetail',
    name: 'FriendDetail',
    component: FriendRequestDetail,
  },
  {
    path: '/friendrequest',
    name: 'FriendRequest',
    component: FriendRequest
  },
  {
    path: '/editlist/:id',
    name: 'EditList',
    component: EditList
  },
  {
    path: '/sharelist',
    name: 'ShareList',
    component: ShareList
  },
  {
    path: '/sharelink',
    name: 'ShareLink',
    component: ShareLink
  },
  {
    path: '/sharereview',
    name: 'ShareReview',
    component: ShareReviews
  },
  {
    path: '/friendlist',
    name: 'FriendList',
    component: FriendList
  },
  {
    path: '/searchfriend',
    name: 'SearchFriend',
    component: SearchFriend
  },
  {
    path: '/addbysnsoremail',
    name: 'AddBySNSorEmail',
    component: AddBySNSorEmail
  },
  {
    path: '/addfriendbyqrcode',
    name: 'AddFriendByQrCode',
    component: AddFriendbyQrCode
  },
   {
    path: '/invites',
    name: 'FriendInvites',
    component: FriendInvites
  },
]


const router = new VueRouter({
  mode: "history",
  base: '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    //https://router.vuejs.org/guide/advanced/scroll-behavior.html
    if (to.hash) {
      return { selector: to.hash }
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 }
    }
  }
});

// Auth guard
router.beforeEach((to, from, next) => {
  const authRequired = !to.matched.some(record => record.meta.public)
  const loggedIn = store.getters.isLoggedIn
  if (to.matched.some(record => record.meta.anyone)) {
    next()
    return
  }
  if (authRequired && !loggedIn) {
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  } else if (!authRequired && loggedIn) {
    next('/')
    return
  }
  next()
})

export default router

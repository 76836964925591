<template>
  <div class="container full-width">
    <Header title="Friend Invitation" />
    <v-skeleton-loader
      v-if="loading"
      class="mx-auto"
      type="card"
    ></v-skeleton-loader>
    <div class="content" v-if="!loading || invites">
      <div class="outer-box">
        <div class="content-box">
          <div class="request">
            <div class="account-time-details">
              List: {{ invites.lists.name }}
            </div>
            <div class="personal-information flex">
              <div class="profile-pic">
                <img src="@/assets/images/avatar.png" v-if="!previewImageURL" />
                <img :src="previewImageURL" v-else />
              </div>
              <div class="nickname-location-selfintroduction">
                <div class="nickname-and-location flex">
                  <div class="nickname">{{ invites.sender.name }}</div>
                </div>
                <div class="self-introduction">
                  <button
                    type="button"
                    class="btn btn-orange"
                    :disabled="invalid"
                    @click.prevent="acceptInvitation(invites.id)"
                  >
                    Accept
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-alert shaped :color="alertType" :value="showAlert" dark dismissible
      >{{ alertMessage }}
    </v-alert>
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
export default {
  components: {
    Footer,
    Header,
  },
  data() {
    return {
      invites: null,
      loading: false,
      invalid: false,
      alertType: null,
      showAlert: false,
      alertMessage: null,
      previewImageURL: null,
    };
  },
  methods: {
    acceptInvitation(id) {
      const payload = {
        id: id,
      };
      this.loading = true;
      const that = this;
      this.$store
        .dispatch("ACCEPT_INVITES_TOKEN", { payload: payload })
        .then(
          (response) => {
            this.alertType = response.data.status;
            this.alertMessage = response.data.message;

            if (response.data.status == "success") {
              this.loading = false;
              this.showAlert = true;
              setTimeout(function () {
                that.$router.push({ name: "List" });
              }, 2000);
            }
            this.loading = false;
            this.showAlert = true;
          },
          (error) => {
            this.loading = false;
            throw error;
          }
        )
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.alertType = "error";
          this.alertMessage = "Some Error Occured, Please try again";
          this.showAlert = true;
        });
    },
  },
  computed: {},

  created() {
    if (!this.$route.query.token) {
      this.$router.push({ name: "TopFeed" });
    } else {
      const payload = {
        token: this.$route.query.token,
      };
      this.loading = true;
      const that = this;
      this.$store
        .dispatch("VERIFY_INVITES_TOKEN", { payload: payload })
        .then(
          (response) => {
            this.alertType = response.data.status;
            this.alertMessage = response.data.message;
            if (response.data.status == "error") {
              this.loading = false;
              this.showAlert = true;
              setTimeout(function () {
                that.$router.push({ name: "TopFeed" });
              }, 2000);
            }
            this.invites = response.data.data;
            if (this.invites.sender.picture) {
              this.previewImageURL = this.invites.sender.picture
                ? process.env.VUE_APP_URL + this.invites.sender.picture
                : null;
            }
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            throw error;
          }
        )
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.alertType = "error";
          this.alertMessage = "Some Error Occured, Please try again";
          this.showAlert = true;
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  .footer {
    max-width: 375px;
    margin: auto;
  }

  .content {
    max-width: 375px;
    margin: auto;
    .outer-box {
      // background: #e5e5e5;
      height: 100vh;
      padding: 15px;
      .content-box {
        .request {
          &:not(:first-child) {
            margin-top: 10px;
          }
          cursor: pointer;
          background: #ffffff;
          border-radius: 0px 3px 3px 0px;
          padding: 20px;
          .account-time-details {
            font-size: 12px;
            color: #b7b7b7;
          }
          .personal-information {
            margin-top: 10px;
            gap: 10px;
            align-items: center;
            .nickname-location-selfintroduction {
              .nickname-and-location {
                justify-content: space-between;
                .nickname {
                  font-size: 15px;
                  color: #000000;
                  margin-bottom: 5px;
                }
                .location {
                  font-size: 14px;
                  color: #b7b7b7;
                }
              }
              .self-introduction {
                button {
                  width: 100%;
                  margin-left: 0px;
                }
              }
            }
          }
        }
      }
    }
  }
  .profile-pic {
    max-width: 50px;
    img {
      width: 100%;
      border-radius: 50%;
    }
  }
}
</style>
<template>
    <div class="container full-width">
        <Header title="Friend request" /> 
      <div class="content">
    <div class="outer-box">
        <div class="content-box" >
        <div class="request" v-for="(request,index) in 3" :key="index" @click="redirectToFriendRequestDetail">
        <div class="account-time-details">
            申請: 2021/11/02 11:40
        </div>
        <div class="personal-information flex">
            <div class="profile-pic">
                <img src="@/assets/images/friendprofilepic.png" />
            </div>
            <div class="nickname-location-selfintroduction">
                <div class="nickname-and-location flex">
                    <div class="nickname">
                        Usernickname
                    </div>
                    <div class="location">
                        <img src="@/assets/images/locationmarker.png" />
                        Tokyo
                    </div>
                </div>
                <div class="self-introduction">
                    Self introductionSelf introductionSelf introductionSelf introductionSelf introduction
                </div>
            </div>
        </div>
        </div>
        </div>
    </div>
      </div>

      <Footer />
    </div>
</template>

<script>
import Footer from '../components/Footer.vue'
import Header from '../components/Header.vue'
export default {
components:{
Footer , Header
},
data(){
    return{
 
    }
},
methods:{
redirectToFriendRequestDetail(){
    this.$router.push({name:'FriendDetail'})
}
}
}
</script>

<style lang="scss" scoped>
.container{
    .footer{
        max-width: 375px;
        margin: auto;
    }
  
    .content{
        max-width: 375px;
        margin: auto;
        .outer-box{
            // background: #e5e5e5;
            height: 100vh;
            padding: 15px;
            .content-box{
                .request{
                &:not(:first-child){
                    margin-top: 10px;
                }
                cursor: pointer;
                background: #FFFFFF;
                border-radius: 0px 3px 3px 0px;
                padding: 20px;
                .account-time-details{
                    font-size: 12px;
                    color: #b7b7b7;
                }
                .personal-information{
                    margin-top: 10px;
                    gap:10px;
                    align-items: center;
                    .nickname-location-selfintroduction{
                        .nickname-and-location{
                            justify-content: space-between;
                            .nickname{
                                font-size: 15px;
                                color: #000000;
                                margin-bottom: 5px;
                            }
                            .location{
                                font-size: 14px;
                                color: #b7b7b7;
                            }
                        }
                        .self-introduction{
                            font-size: 12px;
                            color: #363636;
                        }
                    }
                }
            }
        }
        }
    }
   
}
</style>
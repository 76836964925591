<template>
  <div class="qr-modal">
      <div class="go-back-button" @click="$emit('hide')">
          <img src="@/assets/images/gobackbutton.png" />
      </div>
      <div class="logo flex justify-center">
          <img src="@/assets/images/toast-logo-2.png" />
      </div>
      <div class="qr-image flex justify-center">
          <img src="@/assets/images/shareqr12.png" />
      </div>
      <div class="share-qr-button flex justify-center">
              <button class="share-qr-btn white">
              <img src="@/assets/images/upload.png" /> シェアする
              </button>   
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.qr-modal{
    background: #fff;
    padding: 20px 10px 80px;
    border-radius: 5px;
    width: 100%;
    .logo{
        margin-top: 30px;
    }
    .qr-image{
       margin: 30px 0;
    }
        .share-qr-btn{
            background: #f47500;
            border: none;
            padding: 10px 0;
            width: 70%;
            border-radius: 5px;
        }
    }

</style>

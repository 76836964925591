<template>
  <div class="content">
      <div class="cross-and-scanner">
      <div class="cross-btn" @click="back">
         <img src="@/assets/images/cross-btn.png" />
      </div>
      <div class="qr-scanner flex justify-center">
     <qrcode-vue :value="value" :size="size" level="H"/>
     <div class="line1">
         <img src="@/assets/images/Line5.png" />
     </div>
     <div class="line2">
         <img src="@/assets/images/Line5.png" />
     </div>
     <div class="line3">
         <img src="@/assets/images/Line5.png" />
     </div>
     <div class="line4">
         <img src="@/assets/images/Line5.png" />
     </div>
     </div>
      </div>
     <div class="share-qr">
         <div class="title flex justify-center text-center">
             友達のQRコードをスキャンして
           一緒にtoastを使ってみましょう！
         </div>
         <div class="share-button flex justify-center">
             <button class="share-btn" @click="enableShareQr">自分のQRコードを表示</button>
         </div>
     </div>
     <div class="shareQR" v-if="shareQr">
         <div class="shareQR-inner-box">
            <shareQR v-on:hide="hideShareQr" />
        </div>
     </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import shareQR from '../components/ShareQR.vue'
export default {
data(){
    return {
        value: 'https://google.com',
        size: 200,
        shareQr : false
    }
},
components: {
QrcodeVue , shareQR
},
methods:{
    back(){
        this.$router.go(-1)
    },
    enableShareQr(){
        this.shareQr = true
    },
    hideShareQr(){
        this.shareQr = false
    }
}
}
</script>

<style lang="scss" scoped>
.content{
    max-width: 375px;
    margin: auto;
    position: relative;
    .cross-and-scanner{
        min-height: 60vh;
        padding: 20px;
        background: #c4c4c4;
    .qr-scanner{
        position: relative;
        width: 200px;
        margin: auto;
        margin: 60px auto;
        .line1 , .line2 , .line3 , .line4{
            position: absolute;
        }
        .line1{
            left: -20px;
            top: -20px;
        }
        .line2{
            right: -20px;
            top: -20px;
            transform: rotate(90deg);
        }
        .line3{
            bottom: -20px;
            left: -20px;
            transform: rotate(-90deg);
        }
        .line4{
            bottom: -20px;
            right: -20px;
            transform: rotate(180deg);
        }
    }
    }
    .share-qr{
        min-height: 40vh;
        background: #fff;
        padding: 40px 50px 60px;
        .title{
            font-size: 14px;
            color: #000;
        }
        .share-button{
         margin-top: 30px;
         .share-btn{
             color: #f47500;
             background: transparent;
             border: none;
             padding: 10px 20px;
             border: 1px solid #f47500;
             cursor: pointer;
             border-radius: 5px;
         }
        }
    }
    .shareQR{
        position: absolute;
        top: 0;
        width: 100%;
        padding: 10px 20px;
        background: rgba($color: #000000, $alpha: 0.5);
        height: 100vh;
    }
}
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GmapMap',{staticStyle:{"width":"100%","height":"100vh"},attrs:{"center":{ lat: Number(_vm.center.latitude), lng: Number(_vm.center.longitude) },"zoom":8}},[_vm._l((_vm.userList.list),function(list,listIndex){return _c('div',{key:listIndex},_vm._l((list.reviews),function(review,reviewIndex){return _c('div',{key:reviewIndex},[_c('GmapMarker',{key:reviewIndex,attrs:{"clickable":true,"options":{
          position: _vm.getLocation(review.restaurants),
          icon: require('@/assets/images/passivemapicon.png'),
        }},on:{"click":function($event){return _vm.mark(reviewIndex)}}})],1)}),0)}),_c('GmapMarker',{attrs:{"options":{
      position: _vm.getCenterLocation(_vm.center),
      icon: require('@/assets/images/googlemapactive.png'),
    }},on:{"click":function($event){return _vm.mark(_vm.center)}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="container full-width">
    <Header title="Share List" />
    <div class="content">
      <div class="outer-box">
        <div class="content-box">
          <div class="title flex justify-center">リンクを作成しました！</div>
          <div class="link" v-if="invite_link">
            <input type="text" v-model="invite_link" disabled />
          </div>
          <div class="date-and-time flex justify-center" v-if="invite_link">
            <div class="date">{{ expires_at }}</div>
            <div class="desc">まで有効</div>
          </div>
          <div class="share-button">
            <button class="share-btn" v-if="invite_link" @click="copyLink">
              <div class="flex justify-center button-inner-content">
                <div class="share-icon">
                  <img src="@/assets/images/sharelink.png" />
                </div>
                <div class="share-text white">シェアする</div>
              </div>
            </button>
            <button
              class="share-btn"
              v-else
              @click="sendInvitation"
              :disabled="loading"
            >
              <div class="flex justify-center button-inner-content">
                <div class="share-text white">リンクを生成</div>
              </div>
            </button>
          </div>
          <div
            class="reload-link flex justify-center"
            v-if="invite_link"
            @click="sendInvitation"
          >
            <div class="reload-image">
              <img src="@/assets/images/reload.png" />
            </div>
            <div class="relaod-text">リンクを作成しなおす</div>
          </div>
        </div>
      </div>
    </div>
    <v-alert shaped :color="alertType" :value="showAlert" dark dismissible
      >{{ alertMessage }}
    </v-alert>
    <div class="footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
export default {
  components: {
    Footer,
    Header,
  },
  computed: {},
  data() {
    return {
      alertType: null,
      showAlert: false,
      alertMessage: null,
      invite_link: null,
      expires_at: null,
      loading: false,
    };
  },
  methods: {
    sendInvitation() {
      this.loading = true;
      const payload = {
        sender_id: this.$store.getters.user.id,
      };
      this.$store
        .dispatch("SEND_INVITES", { payload: payload })
        .then(
          (response) => {
            this.loading = false;
            this.alertType = response.data.status;
            this.alertMessage = response.data.message;
            this.invite_link = response.data.data.url;
            this.expires_at = response.data.data.expires_at;
            this.isFriendRequested = true;
            this.showAlert = true;
          },
          (error) => {
            this.loading = false;
            throw error;
          }
        )
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.alertType = "error";
          this.alertMessage = "Some Error Occured, Please try again";
          this.showAlert = true;
        });
    },
    copyLink() {
      navigator.clipboard.writeText(this.invite_link);
      this.alertMessage = "Link copied.";
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .footer {
    max-width: 375px;
    margin: auto;
  }
  .content {
    max-width: 375px;
    margin: auto;
    .outer-box {
      padding: 20px;
      //   background: #e5e5e5;
      height: 100vh;
      .content-box {
        background: #ffffff;
        border: 1px solid #eaeaea;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        padding: 30px 10px;
        .title {
          font-size: 18px;
          color: #f47500;
        }
        .link {
          margin-top: 20px;
          input {
            width: 100%;
            background: #f5f5f5;
            box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
            border-radius: 5px;
            border: none;
            height: 46px;
            padding-left: 10px;
            font-size: 20px;
            color: #bdbdbd;
          }
        }
        .date-and-time {
          gap: 10px;
          font-size: 18px;
          color: #333;
          align-items: flex-end;
          margin: 10px 0;
          .desc {
            font-size: 12px;
          }
        }
        .share-button {
          margin-top: 20px;
          .share-btn {
            border: none;
            width: 100%;
            background: #f47500;
            padding: 10px 0;
            border-radius: 5px;
            .button-inner-content {
              gap: 10px;
            }
            &:disabled {
              opacity: 0.7;
            }
          }
        }
        .reload-link {
          margin-top: 10px;
          gap: 10px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
<template>
  <GmapMap
    :center="{ lat: Number(center.latitude), lng: Number(center.longitude) }"
    :zoom="8"
    style="width: 100%; height: 100vh"
  >
    <div v-for="(list, listIndex) in userList.list" :key="listIndex">
      <div v-for="(review, reviewIndex) in list.reviews" :key="reviewIndex">
        <GmapMarker
          :key="reviewIndex"
          :clickable="true"
          @click="mark(reviewIndex)"
          :options="{
            position: getLocation(review.restaurants),
            icon: require('@/assets/images/passivemapicon.png'),
          }"
        />
      </div>
    </div>
    <GmapMarker
      :options="{
        position: getCenterLocation(center),
        icon: require('@/assets/images/googlemapactive.png'),
      }"
      @click="mark(center)"
    />
  </GmapMap>
</template>

<script>
export default {
  props: {
    selectedList: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },

    userList: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },
  components: {
    // GoogleMap, Marker
  },
  computed: {
    locations() {
      return this.$store.getters.getLocations;
    },
  },
  created() {},

  methods: {
    getLocation(restaurant) {
      return {
        lat: Number(restaurant.latitude),
        lng: Number(restaurant.longitude),
      };
    },

    getCenterLocation() {
      return {
        lat: Number(this.center.latitude),
        lng: Number(this.center.longitude),
      };
    },

    mark(reviewIndex) {
      this.center = this.userList.list[0]?.reviews[reviewIndex]?.restaurants;
      this.$emit("restaurantSelected", reviewIndex);
    },
  },
  data() {
    return {
      center: this.userList.list[0]?.reviews[0]?.restaurants,
    };
  },
  mounted() {
    if (
      !this.$route.query.review &&
      this.$route.query.restaurant &&
      this.$route.query.list
    ) {
      this.$emit("restaurantSelected", "0");
    }
  },
};
</script>

<style>
</style>
<template>
  <div class="container full-width">
    <Header title="Friend List" />
    <v-skeleton-loader
      v-if="loading"
      class="mx-auto"
      type="card"
    ></v-skeleton-loader>
    <div class="content" v-if="!loading">
      <div class="top-box" v-if="hasFriendRequests">
        <div class="notification-sec flex justify-center">
          <div class="notification-icon">
            <img src="@/assets/images/combinedshape.png" />
          </div>
          <div class="notification-text">
            {{ total_request }} 件の友達リクエストがあります
          </div>
        </div>
        <div class="button-and-text" v-if="false">
          <div class="button flex justify-center">
            <button class="submit-btn white">友達を追加する</button>
          </div>
        </div>
      </div>
      <div class="lists">
        <div v-for="(list, index) in allList" :key="index" class="list-card">
          <router-link
            :to="{ name: 'IndividualList', params: { id: list.list_id } }"
            class="link"
          >
            <div class="user-name text-orange fw-700">
              {{ list.lists.name }}
            </div>
            <div class="list-explanation">{{ list.lists.description }}</div>
            <div class="reviews-and-food-categories flex">
              <div class="total-reviews flex"></div>
              <div
                class="selected-categories flex"
                v-if="list.list_owner == $store.getters.user.id"
              >
                <v-btn
                  color="secondary"
                  class="accept-btn"
                  v-if="!isAccepted"
                  @click.prevent="acceptRequest(list.id)"
                >
                  Accept Request
                </v-btn>
                <div v-else><v-chip class="ma-2"> Accepted </v-chip></div>
              </div>
              <div class="selected-categories flex" v-else>
                <v-btn
                  color="secondary"
                  class="accept-btn"
                  v-if="list.status == '0'"
                >
                  Requested
                </v-btn>
                <div v-else><v-chip class="ma-2"> Accepted </v-chip></div>
              </div>
            </div>
            <div class="shadow">
              <img src="@/assets/images/shadow12.png" />
            </div>
          </router-link>
        </div>
        <div v-if="!allList.length">No Friend List found.</div>
        <!-- <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay> -->
      </div>
    </div>
    <v-alert shaped :color="alertType" :value="showAlert" dark dismissible
      >{{ alertMessage }}
    </v-alert>
    <div class="footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
// import AddFriendModal from '../components/AddFriendModal.vue'
import Header from "../components/Header.vue";
export default {
  components: {
    Footer,
    Header,
  },
  data() {
    return {
      totalResturants: 15,
      allList: [],
      total_request: 0,
      hasFriendRequests: false,
      loading: true,
      alertType: null,
      showAlert: false,
      alertMessage: null,
      isAccepted: false,
    };
  },
  methods: {
    redirectToFriendRequest() {
      this.$router.push({ name: "FriendRequest" });
    },
    acceptRequest(id) {
      this.$store
        .dispatch("ACCEPT_FRIEND_LIST", { id: id })
        .then(
          (response) => {
            this.loading = false;
            this.isAccepted = true;
            this.alertType = "success";
            this.alertMessage = response.data.message;
            this.showAlert = true;
          },
          (error) => {
            this.loading = false;
            throw error;
          }
        )
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.alertType = "error";
          this.alertMessage = "Some Error Occured, Please try again";
          this.showAlert = true;
        });
    },
  },
  created() {
    this.$store.dispatch("GET_FRIEND_LIST").then(
      (response) => {
        this.allList = response.data.data;
        if (response.data.new_requests) {
          this.hasFriendRequests = true;
          this.total_request = response.data.new_requests;
        }
        if (this.allList.length) {
          this.isAccepted = this.allList[0].status == "1" ? true : false;
        }
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        throw error;
      }
    );
  },
};
</script>

<style lang="scss" scoped>
.container {
  .footer {
    max-width: 375px;
    margin: auto;
  }
  .content {
    margin: auto;
    max-width: 375px;
    .top-box {
      background: #fff;
      text-align: center;
      padding: 15px 50px;
      .notification-sec {
        cursor: pointer;
        border-bottom: 1px solid #8f4e4f;
        margin-bottom: 15px;
        .notification-text {
          font-size: 15px;
          color: #8f4e4f;
        }
      }
      .button-and-text {
        .text {
          font-size: 12px;
          color: #b3b3b3;
        }
        .button {
          .submit-btn {
            width: 100%;
            padding: 10px 0;
            border: none;
            background: #f47500;
            border-radius: 5px;
            margin-top: 2px;
          }
        }
      }
    }
    .friends {
      background: #e5e5e5;
      padding: 15px;
      .friend {
        position: relative;
        &:not(:first-child) {
          margin-top: 15px;
        }
        gap: 10px;
        background: #fff;
        padding: 20px;
        border-radius: 0 3px 3px 0;
        .avatar {
        }
        .other-information {
          .name-and-location {
            justify-content: space-between;
            .name {
              font-size: 15px;
              color: #000;
            }
            .location {
              color: #b7b7b7;
              font-size: 14px;
            }
          }
          .friend-introduction {
            font-size: 12px;
            color: #363636;
          }
        }
        .shadow {
          position: absolute;
          left: 0;
          bottom: -18px;
        }
      }
    }
  }
}
.lists {
  background: #dddddd;
  padding: 15px;

  .list-card {
    cursor: pointer;
    padding: 12px;
    margin: 15px 0;
    background: #ffffff;
    border-radius: 0px 3px 3px 0px;
    position: relative;

    &:first-child {
      margin-top: 0 !important;
    }

    .user-name {
      font-size: 18px;
    }

    .list-explanation {
      font-size: 14px;
      color: #b7b7b7;
    }

    .reviews-and-food-categories {
      margin-top: 5px;
      justify-content: space-between;

      .total-reviews,
      .selected-categories {
        gap: 10px;
        color: #333333;
        font-size: 14px;
      }
    }

    .shadow {
      position: absolute;
      left: 0;
      bottom: -18px;
    }
    .accept-btn {
      background-color: #ff9b3f;
    }
  }
  .total-reviews {
    display: none;
  }
}
</style>
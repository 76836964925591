<template>
  <div class="top-bar bg-orange flex" :class="{ 'bg-white-': isTopFeed }">
    <div class="back-btn" @click="back()">
      <img src="@/assets/images/back.png" v-if="!isTopFeed" />
      <img src="@/assets/images/logo2.png" v-if="isTopFeed" />
    </div>
    <div class="open-list-text text-white">
      {{ title }}
    </div>
    <div
      class="menu"
      :class="{ changePosition: currentScrollPosition }"
      @click="enableMenu"
    >
      <img src="@/assets/images/white-menu.png" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["title"],
  computed: {
    currentScrollPosition() {
      return this.scrollPosition > 10;
    },
    isTopFeed() {
      return this.$route.name == "TopFeed";
    },
  },
  data() {
    return {
      scrollPosition: null,
    };
  },
  mounted() {
    document.addEventListener("scroll", this.Scrollll);
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    Scrollll() {
      this.scrollPosition = window.scrollY;
    },
    enableMenu() {
      this.$store.commit("toggleMenu");
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-white {
  background: #fff !important;
}
.top-bar {
  justify-content: space-between;
  align-items: center;
  padding: 15px 12px;
  .open-list-text {
    font-size: 18px;
    font-weight: 500;
  }
  .back-btn,
  .menu {
    cursor: pointer;
  }
  .changePosition {
    position: fixed;
    top: 20px;
    right: 10px;
  }
}
</style>
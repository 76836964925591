<template>
  <div class="background">
      <div class="box">
        <div class="text">
            Are you sure you want to delete {{name}} ?
        </div>
        <div class="buttons flex">
            <button class="btn btn-1 white" @click="$emit('close-modal-no','no')">
                NO
            </button>
            <button class="btn btn-2 white" @click="$emit('close-modal-yes','yes')">
                YES
            </button>
        </div>
      </div>
  </div>
</template>

<script>
export default {
props: ['name']
}
</script>

<style lang="scss" scoped>
.background{
width: 100%;
height: 100vh;
position: fixed;
top: 0;
left: 0;
display: flex;
justify-content: center;
align-items: center;
background: rgba($color: #000000, $alpha: 0.5);
.box{
    border-radius: 20px;
    background: #fff;
    padding: 15px;
    .buttons{
        margin-top: 20px;
        .btn{
            background: #f47500;
            padding: 10px;
            cursor: pointer;
            border: none;
        }
        .btn-1 , .btn-2{
            flex: 1;
        }
    }
}
}
</style>
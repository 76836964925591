import axios from "axios";

import {
    GET_ALL_REVIEWS,
    GET_ALL_REVIEWS_SUCCESS,
    GET_ALL_REVIEWS_ERROR,
    GET_REVIEW_DETAIL,
    GET_REVIEW_DETAIL_SUCCESS,
    GET_REVIEW_DETAIL_ERROR,
    SUBMIT_REVIEW,
    UPDATE_REVIEW,
    UPDATE_REVIEW_SUCCESS,
    UPDATE_REVIEW_ERROR,
    DELETE_REVIEW_IMAGE,
    DELETE_REVIEW
} from "@/store/actions/reviews";

function initialState() {
    return {
        allReviews: [],
        review: {}
    }
}

const state = initialState()

const getters = {
    allReviews: (state) => state.allReviews,
    Review: (state) => state.review,
};

const actions = {
    [GET_ALL_REVIEWS]: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            axios.get('/reviews?page=' + params.page)
                .then((response) => {
                    let Reviews = response.data.data.reviews
                    commit("GET_ALL_REVIEWS_SUCCESS", { Reviews });
                    resolve(response.data.data.reviews.data);
                })
                .catch((err) => {
                    commit("GET_ALL_REVIEWS_ERROR");
                    reject(err);
                });
        });
    },

    [SUBMIT_REVIEW]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            axios.post('/reviews', payload, { "Content-Type": "multipart/form-data" })
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    commit("SUBMIT_REVIEW_ERROR");
                    reject(err);
                });
        });
    },

    [GET_REVIEW_DETAIL]: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            axios.get('/reviews/' + params.reviewId)
                .then((response) => {
                    let Review = response.data.data.review
                    commit("GET_REVIEW_DETAIL_SUCCESS", { Review });
                    resolve(response);
                })
                .catch((err) => {
                    commit("GET_REVIEW_DETAIL_ERROR");
                    reject(err);
                });
        });
    },

    [UPDATE_REVIEW]: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            axios.post('/reviews/' + params.reviewId, params.formData)
                .then((response) => {
                    let Review = response.data.data.review
                    commit("UPDATE_REVIEW_SUCCESS", { Review });
                    resolve(response);
                })
                .catch((err) => {
                    commit("UPDATE_REVIEW_ERROR");
                    reject(err);
                });
        });
    },

    [DELETE_REVIEW_IMAGE]: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            axios.get('/delete-review-image/' + params.imageId)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    commit("GET_REVIEW_DETAIL_ERROR");
                    reject(err);
                });
        });
    },

    [DELETE_REVIEW]: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            axios.delete('/reviews/' + params.reviewId)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    commit("GET_REVIEW_DETAIL_ERROR");
                    reject(err);
                });
        });
    },
}

const mutations = {
    [GET_ALL_REVIEWS_SUCCESS]: (state, params) => {
        state.allReviews = params.Reviews;
    },

    [GET_ALL_REVIEWS_ERROR]: () => {
    },

    [GET_REVIEW_DETAIL_SUCCESS]: (state, params) => {
        state.review = params.Review;
    },

    [GET_REVIEW_DETAIL_ERROR]: () => {
    },

    [UPDATE_REVIEW_SUCCESS]: (state, params) => {
        state.review = params.Review;
    },

    [UPDATE_REVIEW_ERROR]: () => {
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
export const GET_ALL_REVIEWS = 'GET_ALL_REVIEWS'
export const GET_REVIEW_DETAIL = 'GET_REVIEW_DETAIL'
export const GET_ALL_REVIEWS_SUCCESS = 'GET_ALL_REVIEWS_SUCCESS'
export const GET_REVIEW_DETAIL_SUCCESS = 'GET_REVIEW_DETAIL_SUCCESS'
export const GET_ALL_REVIEWS_ERROR = 'GET_ALL_REVIEWS_ERROR'
export const GET_REVIEW_DETAIL_ERROR = 'GET_REVIEW_DETAIL_ERROR'
export const UPDATE_REVIEW = 'UPDATE_REVIEW'
export const SUBMIT_REVIEW = 'SUBMIT_REVIEW'
export const UPDATE_REVIEW_SUCCESS = 'UPDATE_REVIEW_SUCCESS'
export const UPDATE_REVIEW_ERROR = 'UPDATE_REVIEW_ERROR'
export const DELETE_REVIEW_IMAGE = 'DELETE_REVIEW_IMAGE'
export const DELETE_REVIEW = 'DELETE_REVIEW'
<template>
  <div class="container full-width">
    <div>
      <Header title="ユーザー情報登録" />
      <div class="content profile">
        <div class="profile-box">
          <validation-observer ref="observer" v-slot="{ invalid }">
            <form @submit="updateProfile">
              <div class="content">
                <div class="top-content flex">(*) は入力必須項目です</div>
                <div class="image-and-name-container">
                  <div
                    class="image-avatar flex justify-center"
                    v-if="!previewImageURL"
                  >
                    <img src="@/assets/images/avatar.png" />
                  </div>
                  <div class="image-avatar flex justify-center" v-else>
                    <v-avatar :size="96">
                      <img :src="previewImageURL" :alt="name" />
                    </v-avatar>
                  </div>
                  <v-file-input
                    accept="image/*"
                    truncate-length="1"
                    @change="previewImage($event)"
                    v-model="picture"
                    prepend-icon="mdi-plus"
                    class="align-center justify-center profile-pic-input"
                  >
                  </v-file-input>
                  <div class="remove_avatar" v-if="previewImageURL">
                    <img
                      src="@/assets/images/review-delete.png"
                      @click.prevent="removeAvatar(userProfile.id)"
                    />
                  </div>

                  <div class="name flex justify-center">画像をアップロード</div>
                </div>
                <div class="more-information">
                  <div class="form-control">
                    <label for="nick-name">NickName *</label>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      name="name"
                    >
                      <!-- <input type="text" id="nick-name" placeholder="Moto" v-model="name"> -->
                      <v-text-field
                        :error-messages="errors"
                        :error="errors.length !== 0"
                        :hide-details="errors.length <= 0"
                        v-model="userProfile.name"
                        dense
                        placeholder="Moto"
                      >
                      </v-text-field>
                    </validation-provider>
                  </div>

                  <div class="form-control">
                    <label for="nick-name">Email *</label>
                    <v-text-field
                      v-model="userProfile.email"
                      dense
                      disabled
                      placeholder="Email"
                    >
                    </v-text-field>
                  </div>

                  <div class="form-control">
                    <label for="nick-name">Phone *</label>
                    <!-- <validation-provider v-slot="{ errors }" rules="required" name="phone"> -->
                    <v-text-field
                      v-model="userProfile.phone"
                      dense
                      placeholder="Phone"
                    >
                    </v-text-field>
                    <!-- </validation-provider> -->
                  </div>

                  <div class="form-control">
                    <label for="self-introduction">Self Introduction</label>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      name="self_introduction"
                    >
                      <v-text-field
                        :error-messages="errors"
                        :error="errors.length !== 0"
                        :hide-details="errors.length <= 0"
                        v-model="userProfile.self_introduction"
                        dense
                        placeholder="If any, text comes here"
                      >
                      </v-text-field>
                    </validation-provider>
                  </div>
                  <div class="form-control">
                    <label for="location">Location *</label>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      name="location"
                    >
                      <v-select
                        v-model="userProfile.location"
                        :error-messages="errors"
                        :error="errors.length !== 0"
                        :hide-details="errors.length <= 0"
                        :items="allLocations"
                        attach
                        chips
                        deletable-chips
                        item-text="ja_name"
                        item-value="id"
                      ></v-select>
                    </validation-provider>
                  </div>
                  <div class="form-control">
                    <label for="dath-birth">Date of Birth</label>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      name="email"
                    >
                      <input
                        type="date"
                        id="date-birth"
                        placeholder="yyyy / mm / dd"
                        v-model="userProfile.dob"
                      />
                      {{ errors[0] }}
                    </validation-provider>
                  </div>

                  <div class="submit-btn flex justify-center">
                    <button
                      type="submit"
                      class="btn"
                      :class="invalid ? 'btn-gray' : 'btn-orange'"
                      :disabled="invalid"
                    >
                      登録する
                    </button>
                  </div>

                  <div class="associate-social">
                    <a
                      href="javascript:void(0)"
                      @click="associateSocialAccounts"
                      >Associate Social Accounts</a
                    >

                    <div class="logins flex flex-column" v-if="associateSocial">
                      <div class="google btn flex">
                        <img src="@/assets/images/google.png" />
                        <div class="text">
                          <a @click.prevent="authProvider('google')"
                            >Login with Google</a
                          >
                        </div>
                      </div>
                      <div class="facebook btn flex">
                        <img src="@/assets/images/fb.png" />
                        <div class="text">
                          <a @click.prevent="authProvider('facebook')"
                            >Login with Facebook</a
                          >
                        </div>
                      </div>
                      <div class="line btn flex">
                        <img src="@/assets/images/line.png" />
                        <div class="text">
                          <a @click.prevent="authProvider('line')"
                            >Login with Line</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </validation-observer>
          <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-alert shaped :color="alertType" :value="showAlert" dark dismissible
            >{{ alertMessage }}
          </v-alert>
        </div>
      </div>
    </div>
    <div class="footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      name: "",
      self_introduction: "",
      location: null,
      dob: null,
      email: null,
      phone: null,
      nicknameLocation: false,
      locationSelected: false,
      loading: false,
      alertType: null,
      showAlert: false,
      alertMessage: null,
      picture: null,
      previewImageURL: null,
      associateSocial: false,
    };
  },
  methods: {
    updateProfile(event) {
      event.preventDefault();
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return;
        }
        this.loading = true;
        let fd = new FormData();
        if (typeof this.userProfile.location === "object") {
          this.userProfile.location = this.userProfile.location.prefecture_id;
        }
        fd.append("name", this.userProfile.name);
        fd.append("phone", this.userProfile.phone);
        fd.append("self_introduction", this.userProfile.self_introduction);
        fd.append("locations", this.userProfile.location);
        fd.append("dob", this.userProfile.dob);
        fd.append("avatar", this.picture);

        this.$store
          .dispatch("UPDATE_PROFILE", fd)
          .then(
            (response) => {
              this.$store.commit("UPDATE_USER", { user: response.data.user });
              this.loading = false;
              this.alertType = "success";
              this.alertMessage = response.data.message;
              this.showAlert = true;
            },
            (error) => {
              this.loading = false;
              this.alertType = "danger";
              this.alertMessage = "Some Error Occue, Please try again";
              this.showAlert = true;
              throw error;
            }
          )
          .catch((error) => {
            this.$refs.observer.setErrors(error.data.errors);
            this.alertType = "danger";
            this.alertMessage = "Some Error Occue, Please try again";
            this.showAlert = true;
            this.loading = false;
          });
      });
    },

    previewImage() {
      this.previewImageURL = URL.createObjectURL(this.picture);
    },

    associateSocialAccounts() {
      this.associateSocial = true;
    },
    authProvider(provider) {
      if (this.loading) {
        return;
      }
      if (provider == "line") {
        location.replace(
          "https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1657343095&redirect_uri=" +
            process.env.VUE_APP_LINE_PROFILE_REDIRECT_URL +
            "&state=" +
            Math.random().toString(36).substr(2) +
            "&scope=profile openid email"
        );
      } else {
        this.$auth
          .authenticate(provider)
          .then((response) => {
            this.socialLogin(provider, response);
          })
          .catch((err) => {
            this.loading = false;
            console.log({ err: err });
          });
      }
    },

    socialLogin(provider, response) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const payload = {
        provider: provider,
        response: response,
      };

      this.$store
        .dispatch("AUTH_ASSOCIATE_ACCOUNTS", payload)
        .then(
          (res) => {
            this.loading = false;
            console.log(res);
          },
          (error) => {
            this.loading = false;
            throw error;
          }
        )
        .catch((error) => {
          this.loading = false;
          if (error)
            if (error.response.data.errors)
              this.error =
                "ログインできません。アカウント情報をご確認ください。";
          this.alertType = "danger";
        });
    },

    loginWithLine() {
      if (this.$route.query.code) {
        if (this.loading) {
          return;
        }
        this.loading = true;
        // this.lineCode = this.$route.query.code;
        const payload = {
          provider: "line",
          line_code: this.$route.query.code,
        };
        this.$store
          .dispatch("AUTH_ASSOCIATE_LINE", payload)
          .then(
            (response) => {
              this.loading = false;
              // if (response.data.status) this.$router.push('/')
              console.log(response);
            },
            (error) => {
              this.loading = false;
              throw error;
            }
          )
          .catch((error) => {
            this.loading = false;
            if (error)
              if (error.response.data.errors)
                this.error =
                  "ログインできません。アカウント情報をご確認ください。";
            this.alertType = "danger";
          });
      }
    },
    removeAvatar(userId) {
      let f = confirm("Are you sure want to delete this?");
      if (f) {
        this.loading = true;
        this.$store
          .dispatch("DELETE_PROFILE_IMAGE", { userId: userId })
          .then((response) => {
            this.previewImageURL = null;
            this.picture = null;
            this.$store.commit("UPDATE_USER", { user: response.data.user });
            this.loading = false;
            this.alertType = "success";
            this.alertMessage = response.data.message;
            this.showAlert = true;
          });
      }
    },
  },
  computed: {
    ...mapGetters(["userProfile", "allLocations"]),
  },
  created() {
    this.loginWithLine();
    this.$store.dispatch("GET_PROFILE");
    this.$store.dispatch("GET_LOCATIONS");
  },
  watch: {
    nickname() {
      this.nickname != ""
        ? (this.nicknameLocation = true)
        : (this.nicknameLocation = false);
    },
    location() {
      this.location != ""
        ? (this.locationSelected = true)
        : (this.locationSelected = false);
    },

    userProfile() {
      this.previewImageURL = this.userProfile.picture
        ? process.env.VUE_APP_URL + this.userProfile.picture
        : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 375px;
  margin: auto;

  justify-content: space-between;

  .logins .btn {
    cursor: pointer !important;
    gap: 10px !important;
    background: #ffffff !important;
    border: 1px solid #adadad !important;
    box-sizing: border-box !important;
    // box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 50px !important;
    padding: 10px !important;
  }

  .top-title {
    background: #f47500;
    height: 36px;
    align-items: center;
  }

  .associate-social {
    text-align: center;
    margin-top: 10px;
  }

  .profile {
    max-width: 375px;
    margin: auto;
    padding-bottom: 80px;
    background: #e5e5e5;

    .profile-box {
      padding: 15px;
      padding-bottom: 80px;
      background: #ffffff;
      border: 1px solid #eaeaea;
      box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      .top-content {
        justify-content: flex-end;
        font-size: 12px;
        color: #333333;
        line-height: 14px;
      }

      .image-and-name-container {
        margin-top: 25px;

        .name {
          margin-top: 5px;
          font-size: 12px;
          color: #929292;
        }
      }

      form {
        .btn {
          background: #cfcfcf;
          border-radius: 5px;
          border: none;
          width: 80%;
          padding: 10px 0;
          margin-top: 40px;
          cursor: pointer;
        }

        .form-control {
          label {
            color: #f47500;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            flex-direction: column;
            margin-top: 15px;
          }

          input,
          select {
            border: none;
            border-bottom: 1px solid #b1b1b1;
            height: 26px;
            color: #333333;
            font-size: 14px;
            padding: 20px 0;
            width: 100%;

            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }
}
.remove_avatar {
  position: absolute;
  top: 90px;
  left: -25px;
  cursor: pointer;
}
.profile-pic-input {
  padding-top: 0px;
  height: 0;
}
.image-avatar .v-avatar {
  width: auto !important;
  height: auto !important;
  min-width: auto !important;
}
.image-avatar .v-avatar img {
  width: 100%;
}
.invite_link {
  margin-top: 1em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}
</style>
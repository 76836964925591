import axios from "axios";

import {
    SEARCH_RESTAURANTS,
    SEARCH_RESTAURANTS_SUCCESS,
    SEARCH_RESTAURANTS_ERROR,
} from "@/store/actions/restaurants";

function initialState() {
    return {
        allRestaurants: [],
        restaurants: {}
    }
}

const state = initialState()

const getters = {
    allRestaurants: (state) => state.allRestaurants,
    restaurants: (state) => state.restaurants,
};

const actions = {
    [SEARCH_RESTAURANTS]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            axios.post('/search-restaurants', payload)
                .then((response) => {
                    let allRestaurants = response.data.data.restaurants
                    commit("SEARCH_RESTAURANTS_SUCCESS", { allRestaurants });
                    resolve(response);
                })
                .catch((err) => {
                    commit("SEARCH_RESTAURANTS_ERROR");
                    reject(err);
                });
        });
    },



}

const mutations = {
    [SEARCH_RESTAURANTS_SUCCESS]: (state, params) => {
        state.allRestaurants = params.allRestaurants;
    },

    [SEARCH_RESTAURANTS_ERROR]: () => {
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
import AuthAPI from "@/services/auth";
import axios from "axios";

import {
    AUTH_REQUEST,
    AUTH_SOCIAL_REQUEST,
    AUTH_ERROR,
    AUTH_SUCCESS,
    AUTH_LOGOUT,
    AUTH_FORGOT,
    AUTH_RESET,
    AUTH_LINE_LOGIN,
    UPDATE_USER,
    AUTH_ASSOCIATE_ACCOUNTS,
    AUTH_ASSOCIATE_LINE,
    SIGNUP_REQUEST,
    VERIFY_TOKEN
} from "@/store/actions/auth";
import router from "@/router/index.js";

function initialState() {
    return {
        token: "",
        isLoggedIn: "",
        status: "",
        user: null,
        firstVisitTime: null
    };
}

const state = {
    token: "",
    status: "",
    isLoggedIn: "",
    user: null,
    firstVisitTime: null
};

const getters = {
    token: (state) => state.token,
    isLoggedIn: (state) => !!state.token,
    user: (state) => state.user,
    userRole: (state) => state.user?.role,
    firstVisitTime: (state) => state.firstVisitTime,
};

const actions = {
    [AUTH_REQUEST]: ({ commit }, user) => {
        return new Promise((resolve, reject) => {
            commit("AUTH_REQUEST");
            AuthAPI.login(user)
                .then((response) => {
                    const user = response.data.data.user;
                    const token = response.data.data.access_token;
                    axios.defaults.headers.common["Authorization"] =
                        "Bearer " + token;
                    commit("AUTH_SUCCESS", { token, user });
                    resolve(response);
                })
                .catch((err) => {
                    commit("AUTH_ERROR");
                    reject(err);
                });
        });
    },

    [AUTH_SOCIAL_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit("AUTH_REQUEST");
            axios.post("/social/" + payload.provider, payload.response)
                .then((response) => {
                    const user = response.data.data.user;
                    const token = response.data.data.access_token;
                    axios.defaults.headers.common["Authorization"] =
                        "Bearer " + token;
                    commit("AUTH_SUCCESS", { token, user });
                    resolve(response);
                })
                .catch((err) => {
                    commit("AUTH_ERROR");
                    reject(err);
                });
        });
    },

    [AUTH_ASSOCIATE_ACCOUNTS]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit("AUTH_REQUEST");
            axios.post("/social-associat-accounts/" + payload.provider, payload.response)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    commit("AUTH_ERROR");
                    reject(err);
                });
        });
    },

    [AUTH_ASSOCIATE_LINE]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit("AUTH_REQUEST");
            axios.post("/social-associat-accounts/" + payload.provider, payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    commit("AUTH_ERROR");
                    reject(err);
                });
        });
    },

    [AUTH_LINE_LOGIN]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit("AUTH_REQUEST");
            axios.post("/social/" + payload.provider, payload)
                .then((response) => {
                    const user = response.data.data.user;
                    const token = response.data.data.access_token;
                    axios.defaults.headers.common["Authorization"] =
                        "Bearer " + token;
                    commit("AUTH_SUCCESS", { token, user });
                    resolve(response);
                })
                .catch((err) => {
                    commit("AUTH_ERROR");
                    reject(err);
                });
        });
    },

    [AUTH_LOGOUT]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios
                .post("logout")
                .then((resp) => {
                    if (resp.status == 204) {
                        commit("AUTH_LOGOUT");
                    }
                    resolve();
                })
                .catch((err) => {
                    commit("AUTH_ERROR");
                    reject(err);
                });
        });
    },

    [SIGNUP_REQUEST]: ({ commit }, user) => {
        return new Promise((resolve, reject) => {
            axios
                .post("sign-up", user)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    commit("AUTH_ERROR");
                    reject(err);
                });
        });
    },

    [VERIFY_TOKEN]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            axios
                .post("verify-token", payload)
                .then((response) => {
                    const user = response.data.data.user;
                    const token = response.data.data.access_token;
                    axios.defaults.headers.common["Authorization"] =
                        "Bearer " + token;
                    commit("AUTH_SUCCESS", { token, user });
                    resolve(response);
                })
                .catch((err) => {
                    commit("AUTH_ERROR");
                    reject(err);
                });
        });
    },

    [AUTH_FORGOT]: ({ commit }, email) => {
        return new Promise((resolve, reject) => {
            axios
                .post("forget-password", email)
                .then((resp) => {
                    if (resp.status == 204) {
                        commit("AUTH_LOGOUT");
                    }
                    resolve(resp);
                })
                .catch((err) => {
                    commit("AUTH_ERROR");
                    reject(err);
                });
        });
    },
    [AUTH_RESET]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            axios
                .post("reset-password", data)
                .then((resp) => {
                    if (resp.status == 204) {
                        commit("AUTH_LOGOUT");
                    }
                    resolve(resp);
                })
                .catch((err) => {
                    commit("AUTH_ERROR");
                    reject(err);
                });
        });
    },
};

const mutations = {
    [AUTH_REQUEST]: (state) => {
        state.status = "loading";
    },

    [AUTH_SUCCESS]: (state, params) => {
        state.status = "success";
        state.token = params.token;
        state.user = params.user;
        state.user.full_name = params.user.name;
    },

    [UPDATE_USER]: (state, params) => {
        state.user = params.user;
    },

    [AUTH_ERROR]: (state) => {
        state.status = "error";
    },

    [AUTH_LOGOUT]: (state) => {
        state.token = "";
        router.push({ name: "Login" }).catch(() => { });

        const s = initialState();
        Object.keys(s).forEach((key) => {
            state[key] = s[key];
        });
        window.localStorage.clear();
        state.status = "";
        state.user = null;
    },

    setUserFirstVisitTime: (state, payload) => {
        state.firstVisitTime = payload;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};

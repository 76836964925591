import axios from "axios";

import {
    UPDATE_PROFILE,
    GET_PROFILE,
    GET_PROFILE_SUCCESS,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_ERROR,
    DELETE_PROFILE_IMAGE,
    GET_USERS,
    SEND_INVITES,
    VERIFY_INVITES_TOKEN,
    ACCEPT_INVITES_TOKEN
} from "@/store/actions/user";

const state = {
    user_profile: null,
};

const getters = {
    userProfile: (state) => state.user_profile,
};

const actions = {
    [UPDATE_PROFILE]: ({ commit }, user) => {
        return new Promise((resolve, reject) => {
            axios.post('/update-profile', user, { 'content-type': 'multipart/form-data' })
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    commit("UPDATE_PROFILE_ERROR");
                    reject(err);
                });
        });
    },

    [GET_PROFILE]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios.get('/get-user-profile')
                .then((response) => {
                    let User = response.data.data.user
                    commit("GET_PROFILE_SUCCESS", { User });
                    resolve(response);
                })
                .catch((err) => {
                    commit("UPDATE_PROFILE_ERROR");
                    reject(err);
                });
        });
    },
    [DELETE_PROFILE_IMAGE]: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            axios.get('/delete-profile-image/' + params.userId)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    commit("UPDATE_PROFILE_ERROR");
                    reject(err);
                });
        });
    },
    [GET_USERS]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios.get('/get-users')
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    commit("UPDATE_PROFILE_ERROR");
                    reject(err);
                });
        });
    },
    [SEND_INVITES]: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            axios.post('/send-invites', params.payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    commit("UPDATE_PROFILE_ERROR");
                    reject(err);
                });
        });
    },
    [VERIFY_INVITES_TOKEN]: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            axios.post('/verify-invites-token', params.payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    commit("UPDATE_PROFILE_ERROR");
                    reject(err);
                });
        });
    },
     [ACCEPT_INVITES_TOKEN]: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            axios.post('/accept-invites', params.payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    commit("UPDATE_PROFILE_ERROR");
                    reject(err);
                });
        });
    },
}

const mutations = {
    [UPDATE_PROFILE_SUCCESS]: () => {
    },

    [GET_PROFILE_SUCCESS]: (state, params) => {
        state.user_profile = params.User;
    },

    [UPDATE_PROFILE_ERROR]: () => {
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
<template>
    <div class="container full-width">
        <Header title="Edit List" />
        <div class="content">
            <div class="outer-box">
                <div class="content-box">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <form class="information" @submit="updateList">
                            <div class="form-control">
                                <label for="list-name">List display name</label>
                                <validation-provider v-slot="{ errors }" rules="required" name="name">
                                    <v-text-field v-model="List.name" :error-messages="errors"
                                        :error="errors.length !== 0" :hide-details="errors.length <= 0" dense>
                                    </v-text-field>
                                </validation-provider>
                            </div>
                            <div class="form-control">
                                <label for="list-name">Favourite cuisin</label>
                                <validation-provider v-slot="{ errors }" rules="required" name="genres">
                                    <v-select v-model="genres" :error-messages="errors" :error="errors.length !== 0"
                                        :hide-details="errors.length <= 0" :items="allGenres" attach chips
                                        deletable-chips multiple item-text="name" item-value="id">
                                    </v-select>
                                </validation-provider>
                            </div>
                            <div class="form-control">
                                <label for="list-explanation">List explanation</label>
                                <validation-provider v-slot="{ errors }" rules="required" name="description">
                                    <v-textarea v-model="List.description" auto-grow row-height="20" rows="1"
                                        :hide-details="errors.length <= 0" :error-messages="errors"
                                        :error="errors.length !== 0">
                                    </v-textarea>
                                </validation-provider>
                            </div>
                            <div class="form-control">
                                <label for="list-access">Access</label>
                                <validation-provider v-slot="{ errors }" rules="required" name="list_aaccess">
                                    <select id="list-access" v-model="List.list_access" :error="errors.length !== 0"
                                        :hide-details="errors.length <= 0" :error-messages="errors">
                                        <option value="0">Public</option>
                                        <option value="1">Friend</option>
                                        <option value="2">Private</option>
                                    </select>
                                </validation-provider>
                            </div>
                            <div class="submit-button flex justify-center">
                                <button class="submit-btn btn" :class="invalid ? 'btn-gray' : 'btn-orange'" :disabled="invalid">更新する</button>
                            </div>
                        </form>
                    </validation-observer>

                    <v-overlay :value="loading">
                        <v-progress-circular indeterminate size="64"></v-progress-circular>
                    </v-overlay>
                    <v-alert shaped :color="alertType" :value="showAlert" dark dismissible>{{ alertMessage }}
                    </v-alert>

                </div>
            </div>
        </div>

        <div class="footer">
            <Footer />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Footer from '../components/Footer.vue'
import Header from '../components/Header.vue'
export default {
    components: {
        Footer, Header
    },

    data() {
        return {
            listName: 'User’s list name',
            listCuisines: '',
            listExplanation: 'List explanation comes here. List explanation comes here. List explanation comes here. List explanation comes here.',
            listAccess: true,
            genres: [],
            loading: false,
            alertType: null,
            showAlert: false,
            alertMessage: null, 
        }
    },

    computed: {
        ...mapGetters(['user', 'List', 'allGenres']),

        currentScrollPosition() {
            return this.scrollPosition > 10
        },
    },

    methods: {
        updateList(event) {
            event.preventDefault();
            this.$refs.observer.validate().then(success => {
                if (!success) {
                    return;
                }
                this.loading = true
                const payload = {
                    name: this.List.name,
                    description: this.List.description,
                    list_access: this.List.list_access,
                    genre: this.genres,
                    user_id: this.user.id
                }

                this.$store
                    .dispatch('UPDATE_LIST', { listId: this.$route.params.id, payload: payload })
                    .then(
                        response => {
                            this.loading = false
                            this.alertType = 'success'
                            this.alertMessage = response.data.message
                            this.showAlert = true
                        },
                        error => {
                            this.loading = false
                            this.alertType = 'danger'
                            this.alertMessage = 'Some Error Occue, Please try again'
                            this.showAlert = true
                            throw error
                        }
                    )
                    .catch(error => {
                        this.$refs.observer.setErrors(error.data.errors)
                        this.alertType = 'danger'
                        this.alertMessage = 'Some Error Occue, Please try again'
                        this.showAlert = true
                        this.loading = false
                    })
            });
        }
    },

    created() {
        this.$store.dispatch('GET_LIST_DETAIL', { listId: this.$route.params.id })
        this.$store.dispatch('GET_ALL_GENERES')
        this.genres = this.List.all_generes ? this.List.all_generes.map((item) => item.id) : []
    }
}
</script>

<style lang="scss" scoped>
.container {
    .footer {
        max-width: 375px;
        margin: auto;
    }

    .content {
        max-width: 375px;
        margin: auto;

        .outer-box {
            // background: #e5e5e5;
            padding: 15px;
            height: 100vh;

            .content-box {
                background: #FFFFFF;
                border: 1px solid #EAEAEA;
                box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
                border-radius: 5px;
                padding: 20px;

                .information {

                    .form-control {
                        &:not(:first-child) {
                            margin-top: 15px;
                        }

                        label {
                            display: block;
                            font-size: 14px;
                            color: #f47500;
                            margin-bottom: 10px;
                        }

                        input,
                        select,
                        textarea {
                            width: 100%;
                            border: none;
                            border-bottom: 1px solid #b1b1b1;
                            padding-bottom: 10px;

                            &:focus {
                                outline: none;
                            }
                        }

                        textarea {
                            height: 80px
                        }
                    }

                    .submit-button {
                        margin-top: 30px;

                        .submit-btn {
                            border: none;
                            background: #f47500;
                            border-radius: 5px;
                            display: block;
                            width: 80%;
                            padding: 10px 0;
                        }
                    }
                }
            }
        }
    }

}
</style>
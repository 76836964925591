import axios from "axios";

import {
    GET_LOCATIONS,
    GET_LOCATIONS_SUCCESS,
    GET_LOCATIONS_ERROR
} from "@/store/actions/prefectures";


function initialState() {
    return {
        prefectures: []
    }
}

const state = initialState()

const getters = {
    allLocations: (state) => state.prefectures,
};

const actions = {
    [GET_LOCATIONS]: ({ commit }, user) => {
        return new Promise((resolve, reject) => {
            axios.get('/get-locations', user)
                .then((response) => {
                    let allLocations = response.data.locations
                    commit("GET_LOCATIONS_SUCCESS", { allLocations });
                    resolve(response);
                })
                .catch((err) => {
                    commit("GET_LOCATIONS_ERROR");
                    reject(err);
                });
        });
    },
}

const mutations = {
    [GET_LOCATIONS_SUCCESS]: (state, params) => {
        state.prefectures = params.allLocations;
    },

    [GET_LOCATIONS_ERROR]: () => {
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
<template>
  <div class="menu">
    <div class="cross-btn" @click="hideMenu">
      <img src="@/assets/images/close.png" />
    </div>
    <div class="menu-items">
      <div class="menu-item" @click="$router.push({ name: 'TopFeed' })">
        トップ
      </div>
      <div class="menu-item" @click="$router.push({ name: 'List' })">
        リスト
      </div>
      <div class="menu-item" @click="$router.push({ name: 'NewReview' })">
        レビュー
      </div>
      <div class="menu-item" @click="$router.push({ name: 'Map' })">マップ</div>
      <div class="menu-item" @click="$router.push({ name: 'Profile' })">
        プロフィール
      </div>
      <div class="menu-item" @click="$router.push({ name: 'FriendList' })">
        友達
      </div>
      <div class="menu-item" @click="$router.push({ name: 'ShareLink' })">
        共有リンク
      </div>
      <div class="menu-item" @click="logout">ログアウト</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    hideMenu() {
      this.$store.commit("toggleMenu");
    },

    logout() {
      this.$store.dispatch("AUTH_LOGOUT").catch((e) => e);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  height: 100vh;
  max-width: 305px;
  background: #fff;
  z-index: 100;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 20px;
  .cross-btn {
    cursor: pointer;
  }
  .menu-items {
    margin-top: 10px;
    .menu-item {
      padding: 5px 0;
      font-size: 20px;
      cursor: pointer;
      transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
      &:not(:last-child) {
        border-bottom: 1px solid #d1d1d1;
      }
      &:hover {
        color: white;
        background: linear-gradient(188.59deg, #f47500 27.77%, #ff9900 77.94%);
      }
    }
  }
}
</style>
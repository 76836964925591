export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SOCIAL_REQUEST = 'AUTH_SOCIAL_REQUEST'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_FORGOT = 'AUTH_FORGOT'
export const AUTH_RESET = 'AUTH_RESET'
export const AUTH_LINE_LOGIN = 'AUTH_LINE_LOGIN'
export const UPDATE_USER = 'UPDATE_USER'
export const AUTH_ASSOCIATE_ACCOUNTS = 'AUTH_ASSOCIATE_ACCOUNTS'
export const AUTH_ASSOCIATE_LINE = 'AUTH_ASSOCIATE_LINE'
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST'
export const VERIFY_TOKEN = 'VERIFY_TOKEN'


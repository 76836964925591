<template>
  <div>
    <div class="container full-width">
      <Header title="レビューの編集" />
      <v-skeleton-loader
        v-if="dataLoading"
        class="mx-auto"
        type="card"
      ></v-skeleton-loader>
      <div class="content" v-if="!dataLoading">
        <div class="box-outer">
          <div class="box">
            <form class="search-form" @submit.prevent="submit">
              <div class="form-control">
                <input
                  type="search"
                  v-model="resturantName"
                  placeholder="お店を入力"
                  disabled
                />
              </div>
            </form>
            <div class="resturant-review-detail">
              <div class="current-resturant flex">
                <div class="information flex flex-column">
                  <div class="category flex">
                    <div class="category-icon">
                      <img src="@/assets/images/knifeporkspoon.png" />
                    </div>
                    <div class="category-text">
                      {{ getGenres(Review.restaurants.restaurant_genres) }}
                    </div>
                  </div>
                  <div class="address flex">
                    <div class="address-icon">
                      <img src="@/assets/images/mapicon.png" />
                    </div>
                    <div class="address-text">
                      {{ getAddress(Review.restaurants) }}
                    </div>
                  </div>
                  <div class="friend flex">
                    <div class="friend-icon">
                      <img src="@/assets/images/btnicon.png" />
                    </div>
                    <div class="friend-text">3 名の友達が話題にしています</div>
                  </div>
                </div>
              </div>
              <validation-observer ref="observer" v-slot="{ invalid }">
                <form @submit="submitReview">
                  <div class="get-feedback">
                    <div class="title text-center">How was it?</div>
                    <div class="get-rating flex justify-center">
                      <star-rating
                        v-model:rating="Review.grade"
                        :rating="0"
                        :max-rating="5"
                        :increment="0.5"
                        inactive-color="#c0c0c0"
                        :rounded-corners="true"
                        :border-color="null"
                      >
                      </star-rating>
                    </div>

                    <div class="pictures flex">
                      <div class="image-icon">
                        <img src="@/assets/images/uploadpictures.png" />
                      </div>
                      <div class="boxes flex">
                        <div
                          class="filebox flex"
                          v-for="(label, index) in uploadedPictures"
                          :key="index"
                          @click="updateFeaturedImage(index)"
                        >
                          <div
                            class="delete-picture"
                            @click="deleteUploadedPicture(label, index)"
                          >
                            <img
                              src="@/assets/images/deleteinput2.png"
                              class="delete-img"
                            />
                          </div>
                          <div
                            class="featured-image"
                            v-if="checkForFeaturedImage(index)"
                          >
                            <img src="@/assets/images/featuredimage.png" />
                          </div>
                          <img :src="label.img" class="review-img" />
                        </div>
                        <label class="upload-image-title">
                          <form method="post" enctype="multipart/form-data">
                            <input
                              type="file"
                              @change="previewFiles($event)"
                              multiple
                              ref="myfiles"
                            />
                          </form>
                          <div class="title">+ 画像追加</div>
                        </label>
                      </div>
                    </div>
                    <div
                      class="featured-image-info flex"
                      v-if="uploadedPictures.length > 0"
                    >
                      <img src="@/assets/images/green-checkmark.svg" />
                      <div>代表画像</div>
                    </div>
                    <div class="comment-date-accesses">
                      <div class="comment-date-accesses-content">
                        <div
                          class="comment flex"
                          v-for="(comment, index) in comments"
                          :key="index + 'dfg'"
                        >
                          <img src="@/assets/images/commentvector.png" />
                          <!-- <input type="text" placeholder="詳細を追加"> -->
                          <input
                            type="text"
                            placeholder="詳細を追加"
                            v-model="reviewComments[index]"
                            class="textarea"
                            role="textbox"
                          />
                          <div
                            class="delete-picture"
                            @click="deleteComment(index)"
                          >
                            <img
                              src="@/assets/images/deleteinput2.png"
                              class="delete-img"
                            />
                          </div>
                        </div>
                        <div @click="addComment" class="add-comment flex">
                          Add Comment
                        </div>
                        <div
                          class="date flex"
                          v-for="(date, index) in dates"
                          :key="index"
                        >
                          <img src="@/assets/images/calendar1.png" />
                          <!-- <input type="date" placeholder="日付を追加" v-model="date.date"> -->
                          <input
                            type="date"
                            placeholder="日付を追加"
                            v-model="reviewDates[index]"
                          />
                          <div class="cross" @click="deleteDate(index)">
                            <img src="@/assets/images/deleteinput2.png" />
                          </div>
                        </div>
                        <div @click="addDate" class="add-date flex">
                          Add Date
                        </div>
                        <div class="access flex">
                          <div class="select-options">
                            <li
                              v-if="publicaccess"
                              @click="changeAccessMode(1)"
                              :class="{ 'list-item-background': menuOpen }"
                            >
                              <div class="flex list-item">
                                <img src="@/assets/images/public.png" />
                                <span>公開 </span>
                              </div>
                            </li>
                            <li
                              v-if="friendaccess"
                              @click="changeAccessMode(2)"
                              :class="{ 'list-item-background': menuOpen }"
                            >
                              <div class="flex list-item">
                                <img src="@/assets/images/access-world.svg" />
                                <span>友達のみ </span>
                              </div>
                            </li>
                            <li
                              v-if="privateaccess"
                              @click="changeAccessMode(3)"
                              :class="{ 'list-item-background': menuOpen }"
                            >
                              <div class="flex list-item">
                                <img src="@/assets/images/access-private.svg" />
                                <span> 私のみ </span>
                              </div>
                            </li>
                            <div class="dropdown" v-if="!menuOpen">
                              <img src="@/assets/images/selectdropdown.svg" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="submit-review flex justify-center">
                    <button
                      class="submit-btn text-white"
                      :class="invalid ? 'btn-gray' : 'btn-orange'"
                      :disabled="invalid"
                    >
                      投稿する
                    </button>
                  </div>
                </form>
              </validation-observer>
            </div>
            <v-overlay :value="loading">
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
            <v-alert
              shaped
              :color="alertType"
              :value="showAlert"
              dark
              dismissible
              >{{ alertMessage }}
            </v-alert>
            <div
              class="delete-review flex justify-center"
              @click="deleteReview(Review.id)"
            >
              <div class="delete-image">
                <img src="@/assets/images/review-delete.png" />
              </div>
              <div class="delete-text">削除する</div>
            </div>
          </div>
        </div>

        <div class="footer">
          <Footer />
        </div>
      </div>
    </div>
    <DeleteConfirmation
      name="Date"
      v-if="deleteConfirmation"
      @close-modal-no="handleClick('no')"
      @close-modal-yes="handleClick('yes', index)"
    />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import StarRating from "vue-star-rating";
import { mapGetters } from "vuex";
import DeleteConfirmation from "../components/DeleteConfirmation.vue";
import Header from "../components/Header.vue";
// StarRating,
export default {
  components: {
    Footer,
    DeleteConfirmation,
    Header,
    StarRating,
  },
  computed: {
    currentScrollPosition() {
      return this.scrollPosition > 10;
    },

    ...mapGetters(["Review"]),
  },

  data() {
    return {
      resturant: "",
      resturantName: this.$route.params.resturantname,
      rating: 4,
      addMore: false,
      deleteConfirmation: false,
      addPictures: true,
      uploadPictures: false,
      uploadedPictures: [],
      reviewComments: [],
      featuredImageIndex: 0,
      publicaccess: true,
      friendaccess: false,
      privateaccess: false,
      menuOpen: false,
      comments: [{ comment: "" }],
      reviewDates: [],
      dates: this.reviewDates,
      reviewId: null,
      loading: false,
      dataLoading: true,
      alertType: null,
      showAlert: false,
      alertMessage: null,
    };
  },

  methods: {
    submitReview(e) {
      e.preventDefault();
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return;
        }
        this.loading = true;
        let fd = new FormData();

        fd.append("comments", this.reviewComments);
        fd.append("dates", this.reviewDates);
        fd.append("access", this.access);
        fd.append("restaurants_id", this.Review.restaurants.id);
        fd.append("grade", this.Review.grade);
        fd.append("review_images", JSON.stringify(this.uploadedPictures));
        fd.append("reviewId", this.reviewId);

        this.$store
          .dispatch("UPDATE_REVIEW", { formData: fd, reviewId: this.reviewId })
          .then(
            (response) => {
              this.loading = false;
              this.alertType = "success";
              this.alertMessage = response.data.data.message;
              this.showAlert = true;
              this.$router.push("/review/" + this.reviewId);
            },
            (error) => {
              this.loading = false;
              this.alertType = "danger";
              this.alertMessage = "Some Error Occue, Please try again";
              this.showAlert = true;
              throw error;
            }
          )
          .catch((error) => {
            // this.$refs.observer.setErrors(error.data.errors)
            this.alertType = "danger";
            this.alertMessage = error.data.message.message;
            this.showAlert = true;
            this.loading = false;
          });
      });
    },

    deleteComment(index) {
      this.reviewComments.splice(index, 1);
      this.comments.splice(index, 1);
    },

    deleteDate(index) {
      this.reviewDates.splice(index, 1);
      this.dates.splice(index, 1);
    },

    getGenres(genres) {
      let allGenres = genres ? genres.map((item) => item.name) : [];
      return allGenres.toString();
    },

    getAddress(restaurant) {
      let postalCode = restaurant
        ? restaurant.postalcode.substr(0, 3) +
          "-" +
          restaurant.postalcode.substr(3, 7)
        : "";
      return (
        "〒" +
        postalCode +
        " " +
        restaurant?.prefecture?.ja_name +
        restaurant?.city?.ja_name +
        restaurant?.address
      );
    },

    getImage(image) {
      return process.env.VUE_APP_URL + image.image;
    },

    addComment() {
      this.comments.push({ comment: "" });
    },

    updateAccess() {
      if (this.Review.review_access == 0) {
        this.publicaccess = true;
        this.privateaccess = false;
        this.friendaccess = false;
        this.access = "0";
      } else if (this.Review.review_access == 1) {
        this.publicaccess = false;
        this.privateaccess = false;
        this.friendaccess = true;
        this.access = "1";
      } else if (this.Review.review_access == 2) {
        this.publicaccess = false;
        this.privateaccess = true;
        this.friendaccess = false;
        this.access = "2";
      }
    },

    showResturant(obj) {
      this.filteredResturants = [];
      this.currentResturant = obj;
      this.addMore = true;
    },

    showCommentDateAccesses() {
      this.addMore = false;
      this.CommentDateAccesses = true;
    },

    addDate() {
      this.dates.push({ date: "date" });
    },

    // deleteDate(index) {
    //     this.dates.splice(index, 1)
    // },

    getConfirmation() {
      this.deleteConfirmation = true;
    },

    handleClick(data, index) {
      if (data == "no") {
        this.deleteConfirmation = false;
        return;
      }
      this.deleteConfirmation = false;
      this.deleteDate(index);
    },

    previewFiles(e) {
      for (let file = 0; file < e.target.files.length; file++) {
        this.toDataURL(
          e.target.files[file],
          function (dataUrl, _this) {
            let pictureDate = {
              img: dataUrl,
              isFeatured: false,
              newUploaded: true,
            };
            _this.uploadedPictures.push(pictureDate);
          },
          this
        );
      }
    },

    toDataURL(url, callback, current) {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result, current);
      };
      reader.readAsDataURL(url);
    },

    enablePictures() {
      this.addPictures = false;
      this.uploadPictures = true;
    },

    deleteUploadedPicture(image, index) {
      if (image.newUploaded == false) {
        this.$store
          .dispatch("DELETE_REVIEW_IMAGE", { imageId: image.image_id })
          .then(
            (response) => {
              console.log(response);
              this.uploadedPictures.splice(index, 1);
              this.checkForFeaturedImage(this.featuredImageIndex);
            },
            (error) => {
              this.loading = false;
              this.alertType = "danger";
              this.alertMessage = "Some Error Occue, Please try again";
              this.showAlert = true;
              throw error;
            }
          )
          .catch((error) => {
            this.alertType = "danger";
            this.alertMessage = error.data.message.message;
            this.showAlert = true;
            this.loading = false;
          });
      } else {
        this.uploadedPictures.splice(index, 1);
        this.checkForFeaturedImage(this.featuredImageIndex);
      }
    },

    checkForFeaturedImage(index) {
      return this.uploadedPictures[index].isFeatured ? true : false;
      // return index == this.featuredImageIndex ? true : false
    },

    updateFeaturedImage(index) {
      Object.keys(this.uploadedPictures).forEach((key) => {
        this.uploadedPictures[key].isFeatured = false;
      });
      this.uploadedPictures[index].isFeatured = true;
      this.featuredImageIndex = index;
      this.checkForFeaturedImage(index);
    },

    changeAccessMode(mode) {
      if (this.menuOpen == false) {
        this.friendaccess = true;
        this.privateaccess = true;
        this.publicaccess = true;
        this.menuOpen = true;
        return;
      }

      if (this.menuOpen == true) {
        if (mode == 1) {
          this.friendaccess = true;
          this.privateaccess = false;
          this.friendaccess = false;
          this.access = "0";
        } else if (mode == 2) {
          this.friendaccess = true;
          this.privateaccess = false;
          this.publicaccess = false;
          this.access = "1";
        } else if (mode == 3) {
          this.friendaccess = false;
          this.privateaccess = true;
          this.publicaccess = false;
          this.access = "2";
        }
        this.menuOpen = false;
      }
    },

    updatePictures() {
      for (let i = 0; i < this.Review.images.length; i++) {
        let pictureDate = {
          img: process.env.VUE_APP_URL + this.Review.images[i].image,
          isFeatured: this.Review.images[i].featured,
          newUploaded: false,
          image_id: this.Review.images[i].id,
        };
        this.uploadedPictures.push(pictureDate);
      }
    },

    updateComments() {
      for (let i = 0; i < this.Review.comments.length; i++) {
        this.reviewComments.push(this.Review.comments[i].comment);
      }
    },

    updateDates() {
      for (let i = 0; i < this.Review.review_dates.length; i++) {
        this.reviewDates.push(this.Review.review_dates[i].review_date);
      }
    },

    deleteReview(reviewId) {
      this.loading = true;
      this.$store.dispatch("DELETE_REVIEW", { reviewId: reviewId }).then(() => {
        this.$router.push({ name: "List" });
      });
    },
  },

  created() {
    // this.$store.dispatch('GET_REVIEW_DETAIL', { reviewId: this.$route.params.id })
    this.$store
      .dispatch("GET_REVIEW_DETAIL", { reviewId: this.$route.params.id })
      .then(
        (response) => {
          if (!response.data.data.review) {
            this.$router.go(-1);
          }
          this.dataLoading = false;
        },
        (error) => {
          this.dataLoading = false;
          throw error;
        }
      );
    this.comments = this.Review.comments;
    this.dates = this.Review.review_dates;
    this.reviewId = this.Review.id;
    this.updateAccess();
    this.updatePictures();
    this.updateComments();
    this.updateDates();
  },
};
</script>

<style lang="scss" scoped>
.list-item-background {
  &:hover {
    box-shadow: 0 0 10px 100px #1882a8 inset;
  }
}

.add-comment {
  justify-content: flex-end;
}

.container {
  .footer {
    max-width: 375px;
    margin: auto;
  }

  .content {
    max-width: 375px;
    margin: auto;

    .box-outer {
      // background: #e5e5e5;
      padding: 15px;

      .box {
        background: #fafafa;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        min-height: 80vh;
        padding: 20px 10px;
        padding-bottom: 100px !important;

        .search-form {
          .form-control {
            position: relative;

            .delete-input {
              cursor: pointer;
              position: absolute;
              top: 0;
              right: 10px;
              display: flex;
              align-items: center;
              height: 100%;
            }

            input {
              width: 100%;
              border: none;
              height: 46px;
              background: #f5f5f5;
              box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
              border-radius: 5px;
              padding-left: 15px;

              &:focus {
                outline: none;
              }
            }

            input[type="search"]::-webkit-search-cancel-button {
              display: none;
            }
          }

          button {
            margin-top: 10px;
            width: 100%;
            background: #eeeae6;
            border-radius: 5px;
            padding: 10px 0;
            border: none;
            cursor: pointer;

            div {
              gap: 10px;
              align-items: center;

              img {
                height: 100%;
              }
            }
          }
        }

        .filteredResturants {
          background: #ffffff;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          padding: 10px;

          .resturant-and-prefacture {
            margin-top: 10px;
            cursor: pointer;

            .resturant {
              font-size: 15px;
              color: #333333;
            }

            .prefacture,
            .slash {
              font-size: 15px;
              color: #bdbdbd;
            }

            .slash {
              margin: 0 5px;
            }
          }
        }

        .resturant-review-detail {
          .current-resturant {
            margin-top: 20px;
            background: #eeeae6;
            border-radius: 5px;
            padding: 10px;
            gap: 10px;

            .information {
              .category,
              .address,
              .friend {
                gap: 10px;
                align-items: flex-start;
              }

              .friend {
                align-items: center !important;
              }

              .address {
                margin: 5px 0;
              }

              .category-text {
                font-size: 15px;
                color: #838383;
                text-transform: capitalize;
              }

              .address-text {
                font-size: 14px;
                color: #333333;
              }

              .friend-text {
                font-size: 14px;
              }
            }
          }

          .get-feedback {
            margin-top: 30px;

            .title {
              color: #333333;
              font-size: 18px;
            }

            .get-rating {
              margin-top: 30px;

              .vue-star-rating {
                .vue-star-rating {
                  .vue-star-rating-star {
                    svg {
                      width: 40px !important;
                    }
                  }
                }
              }
            }

            .upload-pictures {
              cursor: pointer;
              background: #ececec;
              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
              border-radius: 10px;
              padding: 10px;
              margin-top: 30px;

              .image-and-text {
                gap: 20px;
                align-items: center;

                .text {
                  font-size: 18px;
                  color: #868686;
                  line-height: 21px;
                }
              }
            }

            .pictures {
              margin-top: 30px;
              gap: 30px;

              .boxes {
                flex-wrap: wrap;
                gap: 30px;
                align-items: center;

                .filebox {
                  position: relative;
                  background: #ececec;
                  border: 2px solid #b5b5b5;
                  border-radius: 5px;
                  height: 80px;
                  width: 100px;
                  cursor: pointer;
                  display: inline-block;
                }

                .delete-img {
                  position: absolute;
                  top: 5px;
                  right: 8px;
                  //    z-index: 100;
                }

                .review-img {
                  width: 100%;
                  height: 100%;
                }

                .featured-image {
                  position: absolute;
                  top: 10px;
                  left: 10px;
                }

                input[type="file"] {
                  display: none;
                }

                .upload-image-title {
                  cursor: pointer;
                }
              }
            }

            .featured-image-info {
              gap: 10px;
              margin-left: 50px;
              margin-top: 15px;
            }

            .comment-date-accesses {
              margin-top: 30px;

              .add-more {
                cursor: pointer;
              }

              .comment-date-accesses-content {
                .comment {
                  gap: 15px;
                  align-items: center;

                  input {
                    width: 100%;
                    border: none;
                    height: 36px;
                    padding-left: 10px;

                    &:focus {
                      outline: none;
                    }
                  }
                }

                .date {
                  gap: 15px;
                  align-items: center;
                  margin: 10px 0;

                  input {
                    width: 100%;
                    border: none;
                    height: 36px;
                    padding-left: 10px;

                    &:focus {
                      outline: none;
                    }
                  }
                }

                .add-date {
                  justify-content: flex-end;
                  cursor: pointer;
                }

                .dates {
                  .selected-dates {
                    &:last-child {
                      margin-bottom: 20px;
                    }

                    .single-date {
                      display: inline-block;
                      border: 1px solid #888888;
                      border-radius: 20px;
                      padding: 2px 5px;
                      margin-top: 10px;

                      .date-and-cross {
                        align-items: center;
                        gap: 5px;

                        .cross {
                          cursor: pointer;
                        }
                      }
                    }
                  }
                }

                .access {
                  justify-content: space-between;
                  align-items: flex-start;
                  gap: 15px;

                  .images {
                    gap: 10px;
                    margin-right: 10px;
                  }

                  .select-options {
                    flex: 5;
                    position: relative;

                    .dropdown {
                      position: absolute;
                      top: 2px;
                      right: 0;
                    }

                    .list-item {
                      gap: 10px;
                      align-items: center;

                      img {
                        max-width: 26px;
                      }
                    }

                    li {
                      &:not(:first-child) {
                        margin-top: 10px;
                      }

                      list-style: none;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }

          .submit-review {
            width: 100%;
            margin-top: 40px;

            .submit-btn {
              width: 80%;
              background: #f47500;
              border: none;
              border-radius: 5px;
              padding: 10px 0;
              cursor: pointer;
            }
          }
        }

        .delete-review {
          gap: 20px;
          align-items: center;
          cursor: pointer;
          width: fit-content;
          margin: 30px auto 0px auto;

          .delete-text {
            font-size: 18px;
            color: #8f4e4f;
          }
        }
      }
    }
  }
}
</style>
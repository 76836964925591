<template>
  <div class="image-slider-container">
    <div class="content">
      <div class="slider-images">
        <img :src="getImage(unFeaturedImages[currentImageIndex].image)" />
        <div class="arrow left-arrow" @click="changeImage('previous')">
          <img :src="require('@/assets/images/leftarrow.png')" />
        </div>
        <div class="arrow right-arrow" @click="changeImage('next')">
          <img :src="require('@/assets/images/rightarrow.png')" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      unFeaturedImages: [],
      currentImageIndex: 0,
    };
  },
  methods: {
    changeImage(decider) {
      if (decider == "previous" && this.currentImageIndex > 0) {
        this.currentImageIndex--;
      } else if (
        decider == "next" &&
        this.currentImageIndex < this.unFeaturedImages.length - 1
      ) {
        this.currentImageIndex++;
      }
    },

    getImage(image) {
      return process.env.VUE_APP_URL + image;
    },
  },

  created() {
    this.unFeaturedImages = this.images.filter((x) => !x.featured);
  },
};
</script>

<style lang="scss" scoped>
.image-slider-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($color: #000000, $alpha: 0.5);

  .content {
    max-width: 375px;

    .slider-images {
      padding: 0 15px;
      position: relative;

      img {
        object-fit: cover;
        max-width: 100%;
      }

      .arrow {
        position: absolute;
        top: 50%;
        cursor: pointer;
      }

      .right-arrow {
        right: 10px;
      }
    }
  }
}
</style>
<template>
    <div class="container full-width"> 
    <Header title="Share List" /> 
      <div class="content">
          <div class="top-title flex justify-center">
              シェアする内容を選択してください
          </div>
             <div class="list-details ">
          <div class="name-and-like flex">
              <div class="name fw-700 black">
                 User’s list name
              </div>
          </div>
          <div class="nickname">
              User’s nickname here
          </div>
          <div class="list-explanation">
              List explanation comes here if any, if not, no display. List explanation comes here if any, if not, no display. 
          </div>
          <div class="reviews-and-food-categories flex">
          <div class="total-reviews flex">
           <img src="@/assets/images/listcount.png" />
           <div class="review-count">35</div>
       </div>
       <div class="selected-categories flex">
           <img src="@/assets/images/knifeporkspoon.png" />
           <div> Selected Food Categories</div>
       </div>
      </div>
       </div>
       
       <div class="resturant-details">
           <div class="resturant" v-for="resturant in totalResturants" :key="resturant">
               <div class="name">
                         Restaurant name here
               </div>
               <div class="flex checkbox-and-information">
                   <div class="checkbox-container">
                       <input type="checkbox" />
                       <span class="checkmark"></span>
                   </div>
                <div class="flex rating-and-information">
                <div class="rating"> <p class="white"> 5.0  </p>  </div>
                <div class="flex resturant-content">
                    <div class="icons flex flex-column">
                     <div class="category-icon">  <img src="@/assets/images/knifeporkspoon.png" /></div>
                     <div class="location-icon">  <img src="@/assets/images/location.png" /></div>
                     <div class="comment-icon">  <img src="@/assets/images/comment.png" /></div>
                    </div>
                    <div class="texts flex flex-column">
                        <div class="category-text category-text-and-rating flex">
                            <div>
                            Sushi, Bar, Ramen
                            </div>
                            <div class="flex rating">
                               <img src="@/assets/images/camera.png"> 
                               <div> 35 </div>
                            </div>
                            </div>
                        <div class="location-text">Location here</div>
                        <div class="comment-text">if any comments, display that here...</div>
                    </div>
                </div>
                </div>
                </div>
           </div>
           
       </div>
       <div class="submit-button flex justify-center">
           <button class="submit-btn white">リンクを作成する</button>
       </div>
      </div>
    
      <div class="footer">
          <Footer />
      </div>
    </div>
</template>

<script>
import Footer from '../components/Footer.vue'
import Header from '../components/Header.vue'
export default {
components:{
    Footer , Header
},
data(){
    return{
           totalResturants: 15,
    }
},
}
</script>

<style lang="scss" scoped>

.container{
    .footer{
        max-width: 375px;
        margin: auto;
    }

  .content{
      margin: auto;
      max-width: 375px;
      .top-title{
          font-size: 14px;
          color: #333333;
          padding: 12px 0;
          background: #fff;
      }
      .list-details{
        padding: 20px;
        background: #ededed;
        .name-and-like{
          justify-content: space-between;
      //    align-items: center;
          .name{
              font-size: 24px;
          }
          .like{
              cursor: pointer;
          }
          .edit-list{
              font-size: 18px;
              color: red;
              cursor: pointer;
          }
        }
                .list-explanation{
                    font-size: 14px;
                    color: #5b5b5b;
                    margin: 10px 0;
                }
                .nickname{
                    color: #5b5b5b;
                    font-size:14px;
                }
                .reviews-and-food-categories{
                    margin-top: 5px;
                    justify-content: space-between;
                    .total-reviews , .selected-categories{
                        gap: 10px;
                        color: #333333;
                        font-size: 14px;
                    }
                }
    }
    .resturant-details{
         background: #fff;
        padding: 20px;
        .resturant{  
            border-bottom: 1px solid #E3E3E3;
            padding: 10px 0;
            &:first-child{
                padding-top: 0 !important;
            }
             .name{
                   font-size: 18px;
                   color: #333333;
                   margin-bottom: 10px;
                   margin-left: 100px !important;
               }
           .checkbox-and-information{
               gap: 15px; 
               .checkbox-container{
                   margin-top: 8px;
                   display: block;
                   position: relative;
                   padding-left: 35px;
                   margin-bottom: 12px;
                   cursor: pointer;
                   font-size: 22px;
                   user-select: none;
                   .checkmark {
                 position: absolute;
                 top: 0;
                 left: 0;
                 height: 25px;
                 width: 25px;
                 border-radius:50%;
                 background-color: #eee;
                 }
                   input {
                  position: absolute;
                  opacity: 0;
                  cursor: pointer;
                  height: 25px;
                  width: 25px;
                  left: 0;
                  z-index: 3;
                   }
                 input:checked ~ .checkmark {
                   background-color: #f47500 ;
                }
                 
              .checkmark:after {
               content: "";
               position: absolute;
               display: none;
               }

               input:checked ~ .checkmark:after {
               display: block;
                 }

             .checkmark:after {
                left: 9px;
                top: 5px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;
                transform: rotate(45deg);
}
    }
               .rating-and-information{
                   gap: 10px;
               .rating{
                   p{
                 background: linear-gradient(224.31deg, #F57600 19.14%, #FFB82F 80.17%);
                 padding: 10px;
                 border-radius: 50%;
                   }
               }
          .resturant-content{
             
              gap:10px;
              .icons{
                  align-items: center;
                  .location-icon , .comment-icon{
                      margin-top: 5px;
                  }
              }
              .texts{
                  color: #838383;
                  font-size: 14px;
                  .category-text-and-rating{
                      justify-content: space-between;
                      .rating{
                          align-items: center;
                          gap: 10px;
                      }
                  }
                  .location-text , .comment-text{
                      margin-top: 5px;
                  }
              }
          }
               }
        }
        }
        .load-more-text{
            text-align: center;
            font-size: 14px;
            color: #7d7d7d;
            padding-top: 20px ;
            cursor: pointer;
        }
    }
    .submit-button{
        position: fixed;
        bottom: 90px;
        left: 50%;
        transform: translateX(-50%);
        .submit-btn{
            background: #f47500;
            border: none;
            padding: 10px 0;
            border-radius: 5px;
            font-size: 18px;
            width: 250px;
        }
    }
  }
    
   
}
</style>
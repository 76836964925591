<template>
  <v-app>
    <div class="container full-width">
      <Header title="レビューする" />
      <v-skeleton-loader
        v-if="pageLoading"
        class="mx-auto"
        type="card"
      ></v-skeleton-loader>
      <div class="content" v-if="!pageLoading">
        <div class="box-outer">
          <div class="box">
            <form class="search-form" @submit.prevent="submit">
              <div class="search-field d-flex">
                <v-autocomplete
                  v-model="prefecture"
                  :items="allLocations"
                  item-text="ja_name"
                  item-value="id"
                  rounded
                  dense
                  filled
                  chips
                  small-chips
                >
                </v-autocomplete>
                <v-text-field
                  placeholder="お店を入力"
                  v-model="resturant"
                ></v-text-field>
                <div class="search-icon-section" @click="filterResults(true)">
                  <img
                    class="search-icon"
                    src="@/assets/images/search-icon.png"
                  />
                </div>
              </div>
              <button
                v-if="
                  filteredResturants.length == 0 && currentResturant == null
                "
                type="submit"
              >
                <div class="flex justify-center">
                  <img
                    src="@/assets/images/btnicon.png"
                  />お店を選択してください。
                </div>
              </button>
            </form>
            <div
              class="filteredResturants"
              v-if="filteredResturants.length > 0"
            >
              <div class="flex resturant-and-prefacture">
                <div class="resturant">
                  条件に合うレストランは
                  {{ filteredResturants.length }} 件あります。
                </div>
              </div>
              <div
                v-for="resturant in filteredResturants"
                :key="resturant.id"
                @click="showResturant(resturant)"
              >
                <div class="flex resturant-and-prefacture">
                  <div class="resturant">{{ resturant.name }}</div>
                  <div class="slash">/</div>
                  <div class="prefacture">
                    {{ resturant.prefecture.ja_name }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="resturant-review-detail"
              v-if="currentResturant !== null"
            >
              <div
                class="current-resturant flex"
                v-if="currentResturant !== null"
              >
                <div class="information flex flex-column">
                  <div class="name flex mb-1">
                    <div>{{ getRestaurantName(currentResturant) }}</div>
                  </div>
                  <div class="category flex">
                    <div class="category-icon">
                      <img src="@/assets/images/knifeporkspoon.png" />
                    </div>
                    <div class="category-text">
                      {{ getRestaurantCategory(currentResturant) }}
                    </div>
                  </div>
                  <div class="address flex">
                    <div class="address-icon">
                      <img src="@/assets/images/mapicon.png" />
                    </div>
                    <div class="address-text">
                      {{ getRestaurantAddress(currentResturant) }}
                    </div>
                  </div>
                  <div class="friend flex">
                    <div class="friend-icon">
                      <img src="@/assets/images/btnicon.png" />
                    </div>
                    <div class="friend-text">
                      {{ currentResturant.reviews_count }},
                      名の友達が話題にしています
                    </div>
                  </div>
                </div>
              </div>
              <validation-observer ref="observer" v-slot="{ invalid }">
                <form @submit="submitReview">
                  <div class="get-feedback">
                    <div class="title text-center">How was it?</div>
                    <div class="get-rating flex justify-center">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="stars"
                      >
                        <star-rating
                          v-model:rating="rating"
                          :rating="0"
                          :max-rating="5"
                          :error-messages="errors"
                          :error="errors.length !== 0"
                          :hide-details="errors.length <= 0"
                          :increment="0.5"
                          inactive-color="#c0c0c0"
                          :rounded-corners="true"
                          :border-color="null"
                        >
                        </star-rating>
                        {{ errors[0] }}
                      </validation-provider>
                    </div>

                    <div
                      class="upload-pictures"
                      @click="enablePictures"
                      v-if="addPictures"
                    >
                      <div class="flex image-and-text">
                        <div class="image">
                          <img src="@/assets/images/addpicture.svg" />
                        </div>
                        <div class="text">
                          <div>
                            想い出フード<br />
                            画像を追加！
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="pictures flex" v-if="uploadPictures">
                      <div class="image-icon">
                        <img src="@/assets/images/uploadpictures.png" />
                      </div>
                      <div class="boxes flex">
                        <div
                          class="filebox flex"
                          v-for="(label, index) in uploadedPictures"
                          :key="index"
                          @click="updateFeaturedImage(index)"
                        >
                          <div
                            class="delete-picture"
                            v-if="label.img"
                            @click="deleteUploadedPicture(index)"
                          >
                            <img
                              src="@/assets/images/deleteinput2.png"
                              class="delete-img"
                            />
                          </div>
                          <div
                            class="featured-image"
                            v-if="checkForFeaturedImage(index)"
                          >
                            <img src="@/assets/images/featuredimage.png" />
                          </div>
                          <img :src="label.img" class="review-img" />
                        </div>
                        <label class="upload-image-title">
                          <form method="post" enctype="multipart/form-data">
                            <input
                              type="file"
                              @change="previewFiles($event)"
                              multiple
                              ref="myfiles"
                            />
                          </form>
                          <div class="title">+ 画像追加</div>
                        </label>
                      </div>
                    </div>
                    <div
                      class="featured-image-info flex"
                      v-if="uploadedPictures.length > 0"
                    >
                      <img src="@/assets/images/green-checkmark.svg" />
                      <div>代表画像</div>
                    </div>
                    <div class="comment-date-accesses">
                      <div
                        class="add-more flex justify-center"
                        v-if="addMore"
                        @click="showCommentDateAccesses"
                      >
                        <img src="@/assets/images/addmore.svg" />
                      </div>
                      <div
                        class="comment-date-accesses-content"
                        v-if="CommentDateAccesses"
                      >
                        <div
                          class="comment flex"
                          v-for="(comment, index) in comments"
                          :key="index + 'dfg'"
                        >
                          <img src="@/assets/images/commentvector.png" />
                          <input
                            type="text"
                            placeholder="詳細を追加"
                            v-model="reviewComments[index]"
                            class="textarea"
                            role="textbox"
                            @keyup="autogrow"
                          />
                          <div
                            class="delete-picture"
                            @click="deleteComment(index)"
                          >
                            <img
                              src="@/assets/images/deleteinput2.png"
                              class="delete-img"
                            />
                          </div>
                        </div>
                        <div @click="addComment" class="add-comment flex">
                          Add Comment
                        </div>
                        <div
                          class="date flex"
                          v-for="(date, index) in dates"
                          :key="index"
                        >
                          <img src="@/assets/images/calendar1.png" />
                          <input
                            type="date"
                            placeholder="日付を追加"
                            v-model="reviewDates[index]"
                          />
                          <div
                            class="delete-picture"
                            @click="deleteDate(index)"
                          >
                            <img
                              src="@/assets/images/deleteinput2.png"
                              class="delete-img"
                            />
                          </div>
                        </div>
                        <div @click="addDate" class="add-date flex">
                          Add Date
                        </div>
                        <div class="dates">
                          <div
                            class="selected-dates"
                            v-for="(date, index) in reviewDates"
                            :key="index"
                          >
                            <div class="single-date" v-if="date">
                              <div class="flex date-and-cross">
                                <div class="cross-date">{{ date }}</div>
                                <!-- getConfirmation -->
                                <div class="cross" @click="deleteDate(index)">
                                  <img src="@/assets/images/deleteinput1.png" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="access flex">
                          <div class="select-options">
                            <li
                              v-if="publicaccess"
                              @click="changeAccessMode(1)"
                              :class="{ 'list-item-background': menuOpen }"
                            >
                              <div class="flex list-item">
                                <img src="@/assets/images/public.png" />
                                <span>公開 </span>
                              </div>
                            </li>
                            <li
                              v-if="friendaccess"
                              @click="changeAccessMode(2)"
                              :class="{ 'list-item-background': menuOpen }"
                            >
                              <div class="flex list-item">
                                <img src="@/assets/images/access-world.svg" />
                                <span>友達のみ </span>
                              </div>
                            </li>
                            <li
                              v-if="privateaccess"
                              @click="changeAccessMode(3)"
                              :class="{ 'list-item-background': menuOpen }"
                            >
                              <div class="flex list-item">
                                <img src="@/assets/images/access-private.svg" />
                                <span> 私のみ </span>
                              </div>
                            </li>
                            <div class="dropdown" v-if="!menuOpen">
                              <img src="@/assets/images/selectdropdown.svg" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="submit-review flex justify-center">
                    <button
                      class="submit-btn text-white"
                      type="submit"
                      :class="invalid ? 'btn-gray' : 'btn-orange'"
                      :disabled="invalid"
                    >
                      投稿する
                    </button>
                    <!-- @click="submitReview()" -->
                  </div>
                </form>
              </validation-observer>
            </div>
          </div>
          <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-alert shaped :color="alertType" :value="showAlert" dark dismissible
            >{{ alertMessage }}
          </v-alert>
        </div>

        <div class="footer">
          <Footer />
        </div>
      </div>
    </div>
    <DeleteConfirmation
      name="Date"
      v-if="deleteConfirmation"
      @close-modal-no="handleClick('no')"
      @close-modal-yes="handleClick('yes')"
    />
  </v-app>
</template>

<script>
import Footer from "../components/Footer.vue";
import StarRating from "vue-star-rating";
import DeleteConfirmation from "../components/DeleteConfirmation.vue";
import Header from "../components/Header";
// StarRating,
import { mapGetters } from "vuex";
export default {
  components: {
    Footer,
    DeleteConfirmation,
    Header,
    StarRating,
  },
  computed: {
    ...mapGetters(["allRestaurants", "user", "allLocations"]),
  },

  data() {
    return {
      resturant: "",
      prefecture: null,
      pageLoading: true,
      loading: null,
      alertType: null,
      showAlert: null,
      alertMessage: null,
      filteredResturants: [],
      currentResturant: null,
      rating: 0.5, // Dummy rating set for the review, actual rating from the db will replace this
      addMore: false,
      CommentDateAccesses: false,
      dates: [{ date: "" }],
      deleteConfirmation: false,
      addPictures: true,
      uploadPictures: false,
      uploadedPictures: [],
      featuredImageIndex: 0,
      comments: [{ comment: "" }],
      reviewComments: [],
      reviewDates: [],
      dateIndex: null,
      publicaccess: true,
      friendaccess: false,
      privateaccess: false,
      access: "0",
      menuOpen: false,
    };
  },
  methods: {
    submitReview(e) {
      e.preventDefault();
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return;
        }
        this.loading = true;
        let fd = new FormData();

        fd.append("comments", this.reviewComments);
        fd.append("dates", this.reviewDates);
        fd.append("access", this.access);
        fd.append("restaurants_id", this.currentResturant.id);
        fd.append("grade", this.rating);
        fd.append("review_images", JSON.stringify(this.uploadedPictures));
        this.$store
          .dispatch("SUBMIT_REVIEW", fd)
          .then(
            (response) => {
              this.loading = false;
              this.alertType = "success";
              this.alertMessage = response.data.data.message;
              this.showAlert = true;
              this.$router.push({ name: "TopFeed" });
            },
            (error) => {
              this.loading = false;
              this.alertType = "danger";
              this.alertMessage = "Some Error Occue, Please try again";
              this.showAlert = true;
              throw error;
            }
          )
          .catch((error) => {
            this.alertType = "danger";
            this.alertMessage = error.data.message.message;
            this.showAlert = true;
            this.loading = false;
          });
      });
    },

    deleteComment(index) {
      this.reviewComments[index] = null;
      this.comments.splice(index, 1);
    },

    deleteDate(index) {
      this.reviewDates[index] = null;
      this.dates.splice(index, 1);
    },

    refreshForm() {
      this.dates = [{ date: "" }];
      this.comments = [{ comment: "" }];
      this.rating = 0.5;
      this.reviewComments = [];
      this.reviewDates = [];
      this.uploadedPictures = [];
    },

    filterResults() {
      if (this.resturant) {
        this.$store.dispatch("SEARCH_RESTAURANTS", {
          keyword: this.resturant,
          prefecture: this.prefecture,
        });
      }
    },

    clearSearchBar() {
      this.resturant = "";
      this.filteredResturants = [];
      this.currentResturant = null;
      this.CommentDateAccesses = false;
      this.dates = [{ date: "date" }];
      this.addPictures = true;
      this.uploadPictures = false;
      this.uploadedPictures = [];
    },

    showResturant(obj) {
      this.filteredResturants = [];
      this.currentResturant = obj;
      this.addMore = true;
    },

    showCommentDateAccesses() {
      this.addMore = false;
      this.CommentDateAccesses = true;
    },

    addDate() {
      this.dates.push({ date: "date" });
    },

    getConfirmation(index = false) {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let scrollLeft =
        window.pageXOffset || document.documentElement.scrollLeft;
      window.onscroll = function () {
        window.scrollTo(scrollLeft, scrollTop);
      };
      this.dateIndex = index;
      this.deleteConfirmation = true;
    },

    handleClick(data) {
      if (data == "no") {
        this.deleteConfirmation = false;
        window.onscroll = function () {};
        return;
      }
      this.deleteConfirmation = false;
      this.deleteDate(this.dateIndex);
      window.onscroll = function () {};
    },

    async previewFiles(e) {
      for (let file = 0; file < e.target.files.length; file++) {
        await this.toDataURL(
          e.target.files[file],
          function (dataUrl, _this) {
            let pictureDate = {
              img: dataUrl,
              isFeatured: null,
            };
            _this.uploadedPictures.push(pictureDate);
            if (
              !_this.uploadedPictures.filter((item) => item.isFeatured == true)
                .length
            ) {
              _this.updateFeaturedImage(0);
            }
          },
          this
        );
      }
    },

    toDataURL(url, callback, current) {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result, current);
      };
      reader.readAsDataURL(url);
    },

    enablePictures() {
      this.addPictures = false;
      this.uploadPictures = true;
    },

    deleteUploadedPicture(index) {
      this.uploadedPictures.splice(index, 1);
      this.checkForFeaturedImage(this.featuredImageIndex);
    },

    checkForFeaturedImage(index) {
      return this.uploadedPictures[index].isFeatured ? true : false;
      // return index == this.featuredImageIndex ? true : false
    },

    updateFeaturedImage(index) {
      Object.keys(this.uploadedPictures).forEach((key) => {
        this.uploadedPictures[key].isFeatured = false;
      });
      this.uploadedPictures[index].isFeatured = true;

      this.checkForFeaturedImage(index);
    },

    addComment() {
      this.comments.push({ comment: "" });
    },

    autogrow() {
      // this.$refs.comment.style.height = this.calcHeight(this.$refs.comment.style.height.value) + "px";
    },

    calcHeight(value) {
      let numberOfLineBreaks = (value.match(/\n/g) || []).length;
      let newHeight = 20 + numberOfLineBreaks * 20 + 12 + 2;
      return newHeight;
    },

    changeAccessMode(mode) {
      if (this.menuOpen == false) {
        this.friendaccess = true;
        this.privateaccess = true;
        this.publicaccess = true;
        this.menuOpen = true;
        return;
      }

      if (this.menuOpen == true) {
        if (mode == 1) {
          this.friendaccess = true;
          this.privateaccess = false;
          this.friendaccess = false;
          this.access = "0";
        } else if (mode == 2) {
          this.friendaccess = true;
          this.privateaccess = false;
          this.publicaccess = false;
          this.access = "1";
        } else if (mode == 3) {
          this.friendaccess = false;
          this.privateaccess = true;
          this.publicaccess = false;
          this.access = "2";
        }
        this.menuOpen = false;
      }
    },
    getRestaurantName(currentResturant) {
      return currentResturant?.name;
    },
    getRestaurantAddress(currentResturant) {
      let postalCode = currentResturant
        ? currentResturant.postalcode.substr(0, 3) +
          "-" +
          currentResturant.postalcode.substr(3, 7)
        : "";
      return (
        "〒" +
        postalCode +
        " " +
        currentResturant?.prefecture?.ja_name +
        currentResturant?.city?.ja_name +
        currentResturant?.address
      );
    },

    getRestaurantCategory(currentResturant) {
      return currentResturant.restaurant_genres.length > 0
        ? currentResturant.restaurant_genres.map((item) => item.name).toString()
        : [];
    },
  },

  watch: {
    allRestaurants() {
      if (this.allRestaurants.length <= 0) {
        this.currentResturant = null;
        this.filteredResturants = [];
      } else {
        this.filteredResturants = this.allRestaurants;
      }
    },

    resturant() {
      if (this.resturant.length < 3) {
        this.currentResturant = null;
      }
    },
  },

  created() {
    this.$store.dispatch("GET_LOCATIONS").then(
      (response) => {
        console.log(response);
        this.pageLoading = false;
      },
      (error) => {
        this.pageLoading = false;
        throw error;
      }
    );
    if (this.user.user_prefectures[0]) {
      this.prefecture = this.user.user_prefectures[0].id;
    }
  },
};
</script>

<style lang="scss" scoped>
.textarea {
  display: block;
  width: 100%;
  overflow: hidden;
  resize: both;
  min-height: 40px;
  line-height: 20px;
}

.search-icon {
}

.list-item-background {
  &:hover {
    box-shadow: 0 0 10px 100px #1882a8 inset;
  }
}

.container {
  .footer {
    max-width: 375px;
    margin: auto;
  }

  .content {
    max-width: 375px;
    margin: auto;

    .box-outer {
      // background: #e5e5e5;
      padding: 15px;

      .box {
        background: #fafafa;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        min-height: 80vh;
        padding: 20px 10px;
        padding-bottom: 100px !important;

        .search-form {
          .form-control {
            position: relative;

            .delete-input {
              cursor: pointer;
              position: absolute;
              top: 0;
              right: 10px;
              display: flex;
              align-items: center;
              height: 100%;
            }

            input {
              width: 100%;
              border: none;
              height: 46px;
              background: #f5f5f5;
              box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
              border-radius: 5px;
              padding-left: 15px;

              &:focus {
                outline: none;
              }
            }

            input[type="search"]::-webkit-search-cancel-button {
              display: none;
            }
          }

          button {
            margin-top: 10px;
            width: 100%;
            background: #eeeae6;
            border-radius: 5px;
            padding: 10px 0;
            border: none;
            cursor: pointer;

            div {
              gap: 10px;
              align-items: center;

              img {
                height: 100%;
              }
            }
          }
        }

        .filteredResturants {
          background: #ffffff;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          padding: 10px;

          .resturant-and-prefacture {
            margin-top: 10px;
            cursor: pointer;

            .resturant {
              font-size: 15px;
              color: #333333;
            }

            .prefacture,
            .slash {
              font-size: 15px;
              color: #bdbdbd;
            }

            .slash {
              margin: 0 5px;
            }
          }
        }

        .resturant-review-detail {
          .current-resturant {
            margin-top: 20px;
            background: #eeeae6;
            border-radius: 5px;
            padding: 10px;
            gap: 10px;

            .information {
              .category,
              .address,
              .friend {
                gap: 10px;
                align-items: flex-start;
              }

              .friend {
                align-items: center !important;
              }

              .address {
                margin: 5px 0;
              }

              .category-text {
                font-size: 15px;
                color: #838383;
                text-transform: capitalize;
              }

              .address-text {
                font-size: 14px;
                color: #333333;
              }

              .friend-text {
                font-size: 14px;
              }
            }
          }

          .get-feedback {
            margin-top: 30px;

            .title {
              color: #333333;
              font-size: 18px;
            }

            .get-rating {
              margin-top: 30px;

              .vue-star-rating {
                .vue-star-rating {
                  .vue-star-rating-star {
                    svg {
                      width: 40px !important;
                    }
                  }
                }
              }
            }

            .upload-pictures {
              cursor: pointer;
              background: #ececec;
              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
              border-radius: 10px;
              padding: 10px;
              margin-top: 30px;

              .image-and-text {
                gap: 20px;
                align-items: center;

                .text {
                  font-size: 18px;
                  color: #868686;
                  line-height: 21px;
                }
              }
            }

            .pictures {
              margin-top: 30px;
              gap: 30px;

              .boxes {
                flex-wrap: wrap;
                gap: 30px;
                align-items: center;

                .filebox {
                  position: relative;
                  background: #ececec;
                  border: 2px solid #b5b5b5;
                  border-radius: 5px;
                  height: 80px;
                  width: 100px;
                  cursor: pointer;
                  display: inline-block;
                }

                .delete-img {
                  position: absolute;
                  top: 5px;
                  right: 8px;
                  //    z-index: 100;
                }

                .review-img {
                  width: 100%;
                  height: 100%;
                }

                .featured-image {
                  position: absolute;
                  top: 10px;
                  left: 10px;
                }

                input[type="file"] {
                  display: none;
                }

                .upload-image-title {
                  cursor: pointer;
                }
              }
            }

            .featured-image-info {
              gap: 10px;
              margin-left: 50px;
              margin-top: 15px;
            }

            .comment-date-accesses {
              margin-top: 30px;
              padding: 0 15px;

              .add-more {
                cursor: pointer;
              }

              .comment-date-accesses-content {
                .comment {
                  gap: 15px;
                  align-items: center;

                  input {
                    width: 100%;
                    border: none;
                    height: 36px;
                    padding-left: 10px;
                    background: transparent;

                    &:focus {
                      outline: none;
                    }
                  }

                  &:not(:first-child) {
                    margin-top: 10px;
                  }
                }

                .add-comment {
                  margin-top: 10px;
                  justify-content: flex-end;
                  cursor: pointer;
                }

                .date {
                  gap: 15px;
                  align-items: center;
                  margin: 10px 0;

                  input {
                    width: 100%;
                    border: none;
                    height: 36px;
                    padding-left: 10px;
                    background: transparent;

                    &:focus {
                      outline: none;
                    }
                  }
                }

                .add-date {
                  justify-content: flex-end;
                  cursor: pointer;
                }

                .dates {
                  .selected-dates {
                    &:last-child {
                      margin-bottom: 20px;
                    }

                    .single-date {
                      display: inline-block;
                      border: 1px solid #888888;
                      border-radius: 20px;
                      padding: 2px 5px;
                      margin-top: 10px;

                      .date-and-cross {
                        align-items: center;
                        gap: 5px;

                        .cross {
                          cursor: pointer;
                        }
                      }
                    }
                  }
                }

                .access {
                  justify-content: space-between;
                  align-items: flex-start;
                  gap: 15px;

                  .images {
                    gap: 10px;
                    margin-right: 10px;
                  }

                  .select-options {
                    flex: 5;
                    position: relative;

                    .dropdown {
                      position: absolute;
                      top: 2px;
                      right: 0;
                    }

                    .list-item {
                      gap: 10px;
                      align-items: center;

                      img {
                        max-width: 26px;
                      }
                    }

                    li {
                      &:not(:first-child) {
                        margin-top: 10px;
                      }

                      list-style: none;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }

          .submit-review {
            width: 100%;
            margin-top: 40px;

            .submit-btn {
              width: 80%;
              background: #f47500;
              border: none;
              border-radius: 5px;
              padding: 10px 0;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
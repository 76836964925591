<template>
  <div class="container full-width">
    <Header title="Open List" />
    <v-skeleton-loader
      v-if="loading"
      class="mx-auto"
      type="card"
    ></v-skeleton-loader>
    <div class="content" v-if="!loading">
      <div class="search-and-filter">
        <img class="search-icon" src="@/assets/images/search-icon.png" />
        <img class="sort-icon" src="@/assets/images/sort-icon.png" />
        <input type="text" placeholder="Search" />
      </div>
      <div class="lists">
        <div v-for="(list, index) in filterList" :key="index" class="list-card">
          <router-link
            :to="{ name: 'IndividualList', params: { id: list.id } }"
            class="link"
          >
            <div class="user-name text-orange fw-700">{{ list.name }}</div>
            <div class="list-explanation">{{ list.description }}</div>
            <div class="reviews-and-food-categories flex">
              <div class="total-reviews flex">
                <img src="@/assets/images/listcount.png" class="contained" />
                <div class="review-count">{{ list.reviews_count }}</div>
              </div>
              <div class="selected-categories flex">
                <img
                  src="@/assets/images/knifeporkspoon.png"
                  class="contained"
                />
                <div>{{ getGenres(list.all_generes) }}</div>
              </div>
            </div>
          </router-link>
          <div class="shadow">
            <img src="@/assets/images/shadow12.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Footer from "../components/Footer";
import Header from "../components/Header.vue";
export default {
  components: {
    Footer,
    Header,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["allList"]),

    currentScrollPosition() {
      return this.scrollPosition > 10;
    },
    Lists() {
      return this.$store.getters.getListData;
    },
    filterList() {
      let currentUserId = this.$store.getters.user.id;
      return this.$store.getters.allList.filter(function (e) {
        if (e.list_access != "0") {
          if (currentUserId == e.user_id || e.friends.length > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
    },
  },
  methods: {
    getGenres(genres) {
      let allGenres = genres ? genres.map((item) => item.name) : [];
      return allGenres.toString();
    },
  },
  created() {
    this.$store
      .dispatch("GET_ALL_LISTS")
      .then((response) => {
        console.log(response);
        this.loading = false;
        (error) => {
          this.loading = false;
          throw error;
        };
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
      });
  },
};
</script>

<style lang="scss" scoped>
.container {
  .footer {
    max-width: 375px;
    margin: auto;
  }

  margin: auto;

  .content {
    max-width: 375px;
    margin: auto;

    .search-and-filter {
      position: relative;

      img {
        position: absolute;
      }

      .search-icon {
        top: 10px;
        left: 10px;
      }

      .sort-icon {
        top: 10px;
        right: 10px;
      }

      input {
        width: 100%;
        height: 40px;
        padding-left: 40px;
        color: #c9c9c9;
        font-size: 14px;
        border: none;

        &:focus {
          outline: none;
        }
      }
    }

    .lists {
      background: #dddddd;
      padding: 15px;

      .list-card {
        cursor: pointer;
        padding: 12px;
        margin: 15px 0;
        background: #ffffff;
        border-radius: 0px 3px 3px 0px;
        position: relative;

        &:first-child {
          margin-top: 0 !important;
        }

        .user-name {
          font-size: 18px;
        }

        .list-explanation {
          font-size: 14px;
          color: #b7b7b7;
        }

        .reviews-and-food-categories {
          margin-top: 5px;
          justify-content: space-between;

          .total-reviews,
          .selected-categories {
            gap: 10px;
            color: #333333;
            font-size: 14px;
          }
        }

        .shadow {
          position: absolute;
          left: 0;
          bottom: -18px;
        }
      }
    }
  }
}
</style>
<template>
  <div class="container full-width">
    <div>
      <Header title="Review" />
      <v-skeleton-loader
        v-if="loading"
        class="mx-auto"
        type="card"
      ></v-skeleton-loader>
      <div class="content" v-if="!loading">
        <div class="images">
          <div v-if="Review.images.length == 5">
            <img class="review-image" :src="getImage(Review.images[0])" />
            <div class="flex">
              <img
                class="review-image w-50"
                :src="getImage(Review.images[1])"
              />
              <img
                class="review-image w-50"
                :src="getImage(Review.images[2])"
              />
            </div>
            <div class="flex">
              <img
                class="review-image w-50"
                :src="getImage(Review.images[3])"
              />
              <img
                class="review-image w-50"
                :src="getImage(Review.images[4])"
              />
            </div>
          </div>
          <div v-if="Review.images.length == 4">
            <img class="review-image" :src="getImage(Review.images[0])" />
            <img class="review-image" :src="getImage(Review.images[1])" />
            <div class="flex">
              <img
                class="review-image w-50"
                :src="getImage(Review.images[2])"
              />
              <img
                class="review-image w-50"
                :src="getImage(Review.images[3])"
              />
            </div>
          </div>
          <div v-if="Review.images.length == 3">
            <img class="review-image" :src="getImage(Review.images[0])" />
            <div class="flex">
              <img
                class="review-image w-50"
                :src="getImage(Review.images[1])"
              />
              <img
                class="review-image w-50"
                :src="getImage(Review.images[2])"
              />
            </div>
          </div>
          <div v-if="Review.images.length == 2">
            <img class="review-image" :src="getImage(Review.images[0])" />
            <img class="review-image" :src="getImage(Review.images[1])" />
          </div>
          <div v-if="Review.images.length == 1">
            <img class="review-image" :src="getImage(Review.images[0])" />
          </div>
        </div>
        <div class="flex review-btn" @click="pushToEditReview">
          <button class="write-review flex">
            <img src="@/assets/images/listcount.png" />
            <div class="text">
              <router-link
                :to="{ name: 'EditReview', params: { id: Review.id } }"
                class="link"
                >編集
              </router-link>
            </div>
          </button>
        </div>
        <div class="resturant-name">
          {{ Review.restaurants?.name }}
        </div>

        <div class="rating flex justify-center">
          <star-rating
            v-model:rating="Review.grade"
            :rating="0"
            :max-rating="5"
            :increment="0.5"
            read-only
            inactive-color="#c0c0c0"
            :rounded-corners="true"
            :border-color="null"
          >
          </star-rating>
        </div>
        <div class="comments flex">
          <div class="user flex flex-column">
            <img src="@/assets/images/commentusericon.png" />
            <div>{{ Review.user?.name }}</div>
          </div>
          <div class="flex flex-column all-comments" v-if="comments >= 1">
            <div
              class="comment"
              v-for="(comment, commentIndex) in Review.comments"
              :key="commentIndex"
            >
              {{ comment.comment }}
              <div class="date-published flex">
                {{ comment.created_at }}
              </div>
            </div>
          </div>
          <div v-else class="flex justify-center no-review">
            <div class="">No comment...</div>
            <img src="@/assets/images/noreview.png" />
          </div>
        </div>
        <div class="review-dates">
          <ul>
            <li
              v-for="(date, dateIndex) in Review.review_dates"
              :key="dateIndex"
            >
              {{ date.review_date }}
            </li>
          </ul>
        </div>
        <div class="friend-comments" v-if="hasFriendComments">
          <div class="title text-center">Friend,s comments</div>
          <div v-for="comment in friendComments" :key="comment">
            <div class="comments-and-users flex">
              <div class="comment">
                <div class="rating flex">
                  <div
                    v-for="star in Math.round(rating)"
                    :key="star"
                    class="flex star"
                  >
                    <div v-if="star >= rating">
                      <div v-if="rating % 1 == 0">
                        <img src="@/assets/images/star-1.0.svg" />
                      </div>
                      <div v-else>
                        <img src="@/assets/images/star-0.5.svg" />
                      </div>
                    </div>
                    <div v-else>
                      <img src="@/assets/images/star-1.0.svg" />
                    </div>
                  </div>
                </div>
                <div class="text">
                  Comment comes here, a user can add multiple comments under one
                  review if he wants.
                </div>
                <div class="date-and-reviews flex">
                  <div class="total-reviews flex">
                    <img src="@/assets/images/camera.png" />
                    <div>5</div>
                  </div>
                  <div class="date">2021/09/12</div>
                </div>
              </div>
              <div class="user flex flex-column">
                <img src="@/assets/images/commentusericon.png" />
                <div>nickname</div>
              </div>
            </div>
          </div>
          <div class="more-comments text-center">
            See more friend’s comments
          </div>
        </div>
        <div class="resturant-information">
          <div class="logo flex justify-center">
            <img src="@/assets/images/logo3.png" />
          </div>
          <div class="text-center title">Restaurant Information</div>
          <div class="resturant">
            <div class="name fw-700">{{ Review.restaurants.name }}</div>
            <div class="category flex">
              <img src="@/assets/images/knifeporkspoon.png" />
              <div>{{ getGenres(Review.restaurants.restaurant_genres) }}</div>
            </div>
            <div class="address flex" @click="openInMap(Review)">
              <img src="@/assets/images/mapicon.png" />
              <div>{{ getAddress(Review.restaurants) }}</div>
            </div>
            <div class="phone flex">
              <img src="@/assets/images/phoneicon.png" />
              <div>{{ Review.restaurants.phone }}</div>
            </div>
            <div class="website flex">
              <img src="@/assets/images/globeicon.png" />
              <div>{{ Review.restaurants.homepage }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
import StarRating from "vue-star-rating";
export default {
  components: {
    Footer,
    Header,
    StarRating,
  },

  data() {
    return {
      images: 5,
      rating: 5,
      comments: 3,
      friendComments: 3,
      hasFriendComments: true,
      loading: true,
    };
  },

  computed: {
    ...mapGetters(["Review"]),
  },

  methods: {
    openInMap(Review) {
      let routeData = this.$router.resolve({
        name: "Map",
        query: {
          review: Review.id,
          restaurant: Review.restaurants_id,
          list: Review.list_id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    pushToEditReview() {
      this.$router.push({
        name: "EditReview",
        params: { resturantname: "Resturant name here" },
      });
    },

    getImage(image) {
      return process.env.VUE_APP_URL + image.image;
    },

    getGenres(genres) {
      let allGenres = genres ? genres.map((item) => item.name) : [];
      return allGenres.toString();
    },

    getAddress(restaurant) {
      let postalCode = restaurant
        ? restaurant.postalcode.substr(0, 3) +
          "-" +
          restaurant.postalcode.substr(3, 7)
        : "";
      return (
        "〒" +
        postalCode +
        " " +
        restaurant?.prefecture?.ja_name +
        restaurant?.city?.ja_name +
        restaurant?.address
      );
    },
  },

  created() {
    this.$store
      .dispatch("GET_REVIEW_DETAIL", { reviewId: this.$route.params.id })
      .then(
        (response) => {
          if (!response.data.data.review) {
            this.$router.go(-1);
          }
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          throw error;
        }
      );
  },
};
</script>

<style lang="scss" scoped>
.container {
  .footer {
    max-width: 375px;
    margin: auto;
  }

  .review-image {
    width: 100%;
  }

  .review-dates ul {
    list-style-type: none;
    margin-left: 15px;
  }

  .review-dates ul li {
    background-color: #fff7e1;
    width: 80%;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 31px;
    padding-left: 10px;
    border-radius: 5px;
    padding: 10px 10px 30px 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  }

  .content {
    max-width: 375px;
    margin: auto;
    background: #fff;
    padding-bottom: 20px;

    .review-btn {
      justify-content: flex-end;
      margin: 15px;

      .write-review {
        cursor: pointer;
        gap: 10px;
        border: 1px solid #dadada;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 25px;
        padding: 7px;
      }
    }

    .resturant-name {
      font-size: 20px;
      font-weight: 500;
      text-align: center;
    }

    .rating {
      gap: 10px;
      padding: 20px 0;

      img {
        width: 50px;
      }
    }

    .comments {
      padding: 20px;
      gap: 10px;

      .user {
        align-items: center;
        text-align: center;

        img {
          width: 50px;
        }

        div {
          font-size: 12px;
          color: #676767;
        }
      }

      .all-comments {
        gap: 15px;
        width: 75%;
      }

      .comment {
        padding: 12px;
        background: #fff7e1;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        font-size: 12px;
        color: #363636;

        .date-published {
          margin-top: 10px;
          font-size: 12px;
          color: #ccbf9c;
          justify-content: flex-end;
          align-items: flex-end;
        }
      }

      .no-review {
        width: 100%;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
    }

    .friend-comments {
      background: #f3f3f3;
      padding: 30px 20px;

      .title {
        font-size: 18px;
        color: #888888;
      }

      .comments-and-users {
        margin-top: 30px;
        gap: 10px;

        .comment {
          background: #ffffff;
          border: 1px solid #dfdfdf;
          border-radius: 5px;
          padding: 10px;

          .rating {
            padding: 0;
            max-width: 50%;

            .star {
              img {
                width: 30px;
              }
            }
          }

          .text {
            margin: 10px 0;
          }

          .date-and-reviews {
            justify-content: space-between;

            .total-reviews {
              gap: 10px;
              justify-content: center;
              align-items: center;

              div {
                font-size: 14px;
                color: #d8d8d8;
              }
            }

            .date {
              font-size: 12px;
              color: #d3d3d3;
            }
          }
        }

        .user {
          align-items: center;
          width: 10%;

          div {
            font-size: 12px;
            color: #676767;
          }
        }
      }

      .more-comments {
        font-size: 14px;
        color: #7d7d7d;
        margin-top: 30px;
        cursor: pointer;
      }
    }

    .resturant-information {
      padding: 30px 20px;

      .title {
        font-size: 18px;
        color: #888888;
        margin-top: 12px;
      }

      .resturant {
        margin-top: 20px;

        .name {
          font-size: 20px;
        }

        .category,
        .phone,
        .address,
        .website {
          margin-top: 12px;
          gap: 10px;
          font-size: 14px;
          color: #333333;
          align-items: center;
        }
      }
    }
  }
}
</style>
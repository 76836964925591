import axios from 'axios'
import router from '@/router/index'
import store from '@/store/index.js'

// Add a request interceptor
axios.interceptors.request.use(
    config => {
        let source = axios.CancelToken.source();
        const token = store.getters.token
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token
        }
        config.headers['Content-Type'] = 'application/json'
        config.baseURL = process.env.VUE_APP_API_URL
        config.withCredentials = false
        config.cancelToken = source.token
        return config
    },
    error => {
        Promise.reject(error)
    }
)

axios.interceptors.response.use(
    response => {
        // if (response.status === 200) {
        // }
        return response
    },
    function (error) {
        // Do something with response error
        let status = error?.response?.status
        if (status === 401) {
            store.commit('AUTH_LOGOUT')
        } else if(status === 403) {
            router.push('/')
        } else if (status === 404) {
            router.push({
                name: "Not Found"
            })
        }
        return Promise.reject(error.response)
    }
)
